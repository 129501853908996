@import "~antd/dist/antd.css";
@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css");
/*****************************
USE  to define image path
******************************
Media Queries
1. 400, 480, 640, 767, 1024, 1366
2. use Max-Width
******************************/

/** General */

@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/Inter-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/Prompt-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Prompt-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Prompt-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/Prompt-Light.ttf) format("truetype");
}

@font-face{
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face{
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/NotoSansThai-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/NotoSansThai-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/NotoSansThai-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/NotoSansThai-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/NotoSansThai-Bold.ttf) format('truetype');
}

html {
  font-family: "Maven Pro", sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

body {
  font-family: "Maven Pro", sans-serif;
}

button {
  font-family: "Maven Pro", sans-serif;
}

select {
  font-family: "Maven Pro", sans-serif;
}

.btn:hover,
.btn:focus,
.btn:active {
  /* border-color: transparent; */
  box-shadow: none;
}

.topbar .topbar-column {
  width: auto;
}

@font-face {
  font-family: db-helvethaica-x;
  src: url(./assets/fonts/db-helvethaica-x-li.ttf);
}

@font-face {
  font-family: ropasans;
  src: url(./assets/fonts/ropasans-regular.ttf);
}

.accordion .card {
  margin-bottom: 5px;
  border-radius: 5px;
}

.accordion .card:first-of-type {
  border-bottom: 1px solid #e1e7ec;
  border-radius: 5px;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 5px;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 5px;
  border-bottom: 1px solid #e1e7ec;
}

.accordion .card:last-of-type {
  border-radius: 5px;
}

.card-header,
.card-footer {
  background-color: #f5f5f5;
  border-bottom-color: #f5f5f5;
}

.accordion .card:first-of-type {
  border-bottom: 1px solid #e1e7ec;
}

.th-font-large {
  font-size: 22px !important;
  line-height: 1;
}

.th-font {
  font-family: db-helvethaica-x;
}

main {
  background-color: #ffffff;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 95vw;
}

.modal-content {
  min-width: 350px;
}

.page-container,
#page-wrap {
  /* background-color: #ffffff; */
  height: 100%;
}

.social-icons {
  display: none;
}

iconHover {
  cursor: pointer;
}

iconHover:hover {
  cursor: pointer;
  color: #0da9ef;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.pills .btn-outline-primary.focus,
.pills .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 !important;
}

.dropdown-menu {
  margin: 0.4rem !important;
}

.reportTab .dropdown-menu {
  width: 225px;
  margin: 0.4rem auto !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle,
.btn-outline-secondary:hover {
  background: transparent;
  color: #606975;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: #0aa9ef;
  border-color: #0aa9ef;
  box-shadow: none;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border-color: #e1e7ec;
}

.dropdown-menu a.dropdown-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.checkout-footer {
  padding: 20px;
}

.checkout-footer.narrow {
  padding: 14px 0 10px 0;
}

.checkout-footer button {
  margin-bottom: 5px !important;
}

.checkout-footer.narrow button {
  margin-bottom: 7px !important;
}

.checkout-footer.not-login {
  height: 306px;
  margin-top: 0px;
}

.checkout-footer .btn > i,
.checkout-footer a > i {
  margin-top: -4px;
}

.checkout-footer a.btn.btn-primary span,
.checkout-footer a.btn.btn-primary i {
  color: #fff;
}

.checkout-payment.checkout-footer .btn {
  width: 100%;
  font-weight: bold;
}

.modal-dialog {
  width: auto !important;
}

.max-height-160 {
  max-height: 160px !important;
}

/*Sathya's UI changes*/

.transparent-bg {
  background-color: transparent;
}

.ushop-webview-footer-icon {
  font-size: 12;
  font-weight: bold;
}

.border-none {
  border: none;
}

.alignSelf-center {
  align-self: center;
}

.ushop-webview-footer {
  position: fixed;
  bottom: 0;
  z-index: 2222;
  left: 0;
  right: 0;
  text-align: center;
  height: 55px;
  /* border: 1px grey; */
  box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
  -webkit-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
  -moz-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
}

.ushop-webview-footer-container {
  height: 55px;
  border-top: 1px solid rgb(225, 231, 236);
  background-color: white;
}

.min-width-150 {
  min-width: 150 !important;
}

.min-height-150 {
  min-height: 150 !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.max-height-180 {
  max-height: 180px;
}

.margin-right-10-imp {
  margin-right: 10px !important;
}

.padding-1x {
  padding: 24px;
}

.slick-slide-500.slick-slide {
  height: 500px !important;
}

.padding-left-15 {
  padding-left: 15px;
}

.absolute-center {
  margin: auto;
  left: 0;
  right: 0;
}

.margin-left-1x {
  margin-left: 24px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.height-30 {
  height: 30px;
  max-height: 30px;
}

.max-width-80x {
  max-width: 80px !important;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-right-null {
  padding-left: 0px;
  padding-right: 0px;
}

.max-width-100x {
  max-width: 100px;
}

.padding-left-1x {
  padding-left: 24px;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-left-null {
  padding-left: 0px !important;
}

.padding-010 {
  padding: 0 10% !important;
}

.radio-button-style {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.display-inline {
  display: inline;
}

.padding-left-30 {
  padding-left: 30px;
}

.height-60 {
  height: 50px !important;
  max-height: 50px !important;
  min-height: 50px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.text-success {
  color: #27ae60;
}

.button-center {
  margin: 0 auto;
  display: block;
}

.notification-icon {
  /*width: 30px;*/
  transition: background-color 0.35s;
  color: #606975;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  padding-top: 4px;
}

.padding-24 {
  padding: 24px;
}

.btn-xs {
  height: 28px;
  padding: 5px 30px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 20px;
}

.genealogy-page-wrapper .btn {
  font-family: "Maven Pro", sans-serif !important;
  line-height: 0 !important;
  letter-spacing: 0 !important;
  font-weight: 100 !important;
  text-transform: unset;
  margin-right: 0px;
}

.notification-modal {
  width: 450px;
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.width-60 {
  width: 60px;
}

.vertical-center-align {
  position: absolute !important;
  top: 44% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 50% !important;
  height: 50% !important;
}

.vertical-center-align-btn {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.padding-border-top-color {
  padding-top: 0px !important;
  border-top: 0px !important;
}

.min-width-225 {
  min-width: 225px;
}

.padding-15 {
  padding: 15px;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.ref-table p {
  font-size: 16px;
}

.mob-cart-img {
  display: none;
}

.order-history p {
  margin-left: 33%;
}

.order-history img {
  margin-left: 33%;
}

.orderid-inline {
  display: inline-block;
}

.order-items .order-history {
  text-align: center;
}

.order-items .col {
  margin: auto;
}

.form-check label {
  text-align: center;
  display: block;
}

.form-check a {
  white-space: nowrap;
}

.enrollment-page div > h4 {
  text-align: center;
}

.custom-control .agree-tc-label {
  padding-left: 5px !important;
}

.custom-control .custom-control-label {
  padding-left: 5px !important;
}
.custom-control-label::before,
.custom-control-label::after {
  left: 0;
  top: 2px;
  width: 14px;
  height: 14px;
}

.form-city .pills {
  margin-top: 0;
}

.form-city .dropdown-toggle::after {
  margin-top: 18px !important;
  float: right !important;
}

.form-city .dropdown-menu.show {
  max-height: 600px;
  overflow: scroll;
}

.slick-list .slick-slide .header {
  font-size: 40px;
}

.slick-list .slick-slide .body {
  font-size: 18px;
}

.slick-list .slick-slide .mb_header {
  font-size: 28px;
}

.checkTC {
  width: 320px;
}

.TH .checkTC {
  width: 240px;
}

.checkTC label {
  width: 300px;
}

.pills .btn {
  text-transform: capitalize;
}

a.order-button-report:not([href]):not([tabindex]) {
  color: #48a8e9;
}

.order-button-report.active:not([href]):not([tabindex]) {
  color: #f0f0f0;
}

.order-button-report.active {
  background-color: #0aa9ef;
}

.order-button-report.active::before {
  font-family: feather;
  content: "\e042";
  margin-right: 5px;
}

.not-login .custom-control-label::before,
.not-login .custom-control-label::after {
  left: 2px;
}

.scrollable {
  overflow: auto;
  white-space: nowrap;
  margin: 0 -15px;
}
.scrollable button:first-child {
  margin-left: 16px;
}
.scrollable button:last-child {
  margin-right: 16px;
}
i.icon-bag {
  top: 4px !important;
}
i.icon-head {
  top: 5px !important;
}
@media only screen and (max-width: 320px) {
  .youtube-vid {
    padding: 15px;
    border-radius: 10px !important;
  }
  .look-forward {
    padding: 15px;
    border-radius: 10px !important;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 08px;
  }
  .small-device-text-right {
    text-align: right;
  }
  .small-device-margin-top-18 {
    margin-top: 18px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 70px;
  }
  .single-product-resp-img {
    max-height: 250px;
  }
  .order-history p {
    margin-left: 10%;
  }
  .order-history img {
    margin-left: 10%;
  }
  .agreeTC-center::before,
  .agreeTC-center::after {
    left: auto;
  }
  .checkTC {
    width: 190px;
  }
  .checkTC label {
    width: 210px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .order-history p {
    margin-left: 10%;
  }
  .order-history img {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  /* .agreeTC-center::before,
    .agreeTC-center::after {
        left: 50px;
    } */
}

@media only screen and (min-width: 350px) and (max-width: 419px) {
  .order-history p {
    margin-left: 20%;
  }
  .order-history img {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 480px) {
  .youtube-vid {
    padding: 15px;
    border-radius: 10px !important;
  }
  .look-forward {
    padding: 15px;
    border-radius: 10px !important;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 08px;
  }
  .small-device-text-right {
    text-align: right;
  }
  .small-device-margin-top-18 {
    margin-top: 18px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 70px;
  }
  .single-product-resp-img {
    max-height: 270px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .youtube-vid {
    padding: 15px;
  }
  .look-forward {
    padding: 15px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .event-promotional-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 340px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 15px !important;
  }
  .global-connected-button-left {
    margin-left: 20px;
  }
  .gene-search {
    float: left;
  }
  .slick-initialized .slick-slide .product-card .product-thumb {
    display: block;
  }
}

.pills > button[disabled] {
  border-color: #dbe2e8 !important;
  color: #495057 !important;
  background: #f5f5f5 !important;
}

@media only screen and (min-width: 959px) and (max-width: 1200px) {
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 430px;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
}

@media only screen and (min-width: 959px) and (max-width: 991px) {
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .event-promotional-border-radius {
    border-radius: 10px !important;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .responsive-top {
    top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2560px) {
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 430px;
  }
  .border-shadow-count {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
}

.minHeight230 {
  min-height: 230px !important;
}

.unicity-place {
  font-size: 25px;
  color: red;
}

.border-normal-radius {
  border-radius: 10px;
}

.border-radius-left-top-bottom {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bottom-click-button {
  position: absolute;
  margin-left: -100px !important;
  left: 50%;
  width: 200px;
  bottom: 24px;
}

.bottom-click-button-copy {
  top: 0;
}

.bottom-click-button-copy-mobile {
  bottom: 10px !important;
}

.bottom-click-button-space {
  bottom: -20px !important;
}

.navi,
.infoi {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.infoi {
  z-index: 10;
}

.bottom-place-text {
  position: absolute;
  bottom: 65px;
  right: 0;
}

.bottom-place-button {
  position: absolute;
  bottom: 15px;
  right: 0;
}

.margin-right-10 {
  margin-right: 10px;
}

@media (max-width: 330px) {
  .element {
    background: #cccccc;
  }
}

@media (max-width: 860px) and (min-width: 767px) {
  .element {
    background: #888888;
  }
}

@media (max-width: 897px) and (min-width: 321px) {
  .element {
    background: #222222;
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.vertical-center-span {
  display: inline-flex;
  align-items: center;
}

.width-225 {
  width: 225px !important;
}

.width-175 {
  width: 175px !important;
}

.dropDownPlace {
  float: right;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.search-style {
  height: 50px !important;
  position: relative !important;
}

.search-style .site-search .search-tools {
  margin-top: 15px !important;
}

.shopping-search {
  padding-right: 35px !important;
  padding-left: 0px !important;
}

.site-search .search-tools .close-search {
  margin-left: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
}

.input-range__label {
  font-family: "Maven Pro", san-serif !important;
}

.lsb-header {
  background: #606975;
  color: #ffffff;
}

.display-block {
  display: block !important;
}

.profile-pic {
  height: 115px;
  width: 115px;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
}

.profile-pic-sm {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 20px;
  /*margin-bottom: 20px;*/
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 10;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}

.user-avatar:hover .overlay {
  opacity: 0.9;
}

.iconUpload:hover .overlay {
  opacity: 1;
}

.iconUpload {
  color: #48a8e9;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-upload:hover {
  color: #48a8e9;
  opacity: 1;
}

.over-flow {
  overflow-y: scroll;
  height: 350px;
  left: -20px !important;
  text-align: center !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-90 {
  min-width: 90px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.profile-pic-side {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin: 0px 0px 0px 0px;
  border: 1px solid white;
  padding: 1px;
}

.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 1.25em;
  /* fallback */
  max-height: 2.5em;
  height: 2.5em;
  display: block;
}

.minHeight {
  height: 150px !important;
}

.productImage {
  width: auto;
  height: 200px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.margin-top-medium {
  margin-top: 08px !important;
}

.place-text-center {
  text-align: center !important;
  left: -45px !important;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  color: #0da9ef !important;
}

.separator-line {
  position: relative;
  border-left: 1px solid;
  right: -149px;
  z-index: 2;
  border-color: white;
}

/* padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); */

/* End of general */

/** Utilities */

.hover-shadow-qs {
  transition: all 0.25s ease-in-out;
}

.hover-shadow-qs:hover {
  -webkit-box-shadow: 0px 0px 3px 3px #ccc;
  -moz-box-shadow: 0px 0px 3px 3px #ccc;
  box-shadow: 0px 0px 3px 3px #ccc;
}

.hover-shadow {
  transition: all 0.25s ease-in-out;
}

.hover-shadow:hover {
  /* -webkit-box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1);
    box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1); */
  -webkit-box-shadow: 0px 0px 3px 3px #ccc;
  -moz-box-shadow: 0px 0px 3px 3px #ccc;
  box-shadow: 0px 0px 3px 3px #ccc;
}

.img-promotions {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.footer-phone a {
  text-decoration: none;
  color: white;
}

.footer-phone a:hover {
  color: white;
}

.hide {
  display: none !important;
}

.no-margin {
  margin: 0px;
}

.sidebar-hover {
  transition: all 0.1s ease-in-out;
}

.sidebar-div-hover :hover .sidebar-hover {
  color: #0da9ef;
  transition: all 0.1s ease-in-out;
}

.sidebar-hover > span:hover {
  color: #0da9ef;
  transition: all 0.1s ease-in-out;
}

.success-label {
  position: relative;
  z-index: 2;
  color: #3c4246;
  /* color:white; */
  bottom: 1px;
}

.loadingImage {
  min-height: 75px;
  background-position: center;
  background-image: url(./assets/loadding.gif);
  background-repeat: no-repeat;
  background-size: 75px;
}

.loading-image-container {
  width: 75px !important;
  height: 75px !important;
  -webkit-transform: rotateZ(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotateZ(0deg) !important;
}

.success-position {
  position: relative;
  right: 30px;
}

.no-padding {
  padding: 0px !important;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-top-80 {
  margin-top: 80px;
}
.margin-top-40 {
  margin-top: 40px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.top-0 {
  top: 0px !important;
}

.top-40 {
  top: 40px !important;
}

.padding-top-20 {
  padding-top: 20px;
}

.bg-white {
  background-color: #ffffff;
}

.width-300 {
  width: 300px !important;
}

.width-30 {
  width: 30px !important;
}

.profile-Icon {
  display: inline-block;
  border-radius: 60px;
  box-shadow: rgb(136, 136, 136) 0px 0px 2px;
  position: absolute;
  right: 8px;
  padding: 0px 8px;
  background-color: #fff;
  font-size: 12px;
  line-height: 28px;
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: none;
}

.slick-slide.slick-active {
  z-index: 999;
}

.profile-label {
  position: absolute;
  right: 0;
  top: 0;
}

.inline-block {
  display: inline-block;
}

.push-overlay {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 999;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}

.scroll-to-top-btn {
  border: 0px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  right: 32px !important;
  z-index: 200 !important;
}

.scroll-to-top-btn:hover {
  background-color: transparent;
  color: #fff;
}

.scroll-to-top-btn.visible {
  border: 0px;
  bottom: 40px;
}

.progress-fixed {
  width: 100%;
  padding-top: 20px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;
  transition: 0.5s;
}

.redirect-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.progress-inner {
  border-radius: 4px;
  position: absolute;
  left: 15px;
  right: 15px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.progress-point {
  border-radius: 30px;
  position: absolute;
  margin-top: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.sub-menu-separator {
  margin-top: 5px;
  margin-bottom: 3px;
  border-top: 1px solid #e1e7ec;
}

.next-btn {
  height: 35px;
  background: #0da9ef;
  border: 0;
  color: #fff;
  width: 100px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}

.danger-btn {
  background-color: #ff5252;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.info-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 20px;
  padding-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.primary-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: px;
}

.primary-btn:hover {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.shortcut {
  background-color: white;
  color: black;
}

.order-heading {
  font-size: 25px;
  border-bottom: 1px solid;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  margin-bottom: 0px;
}

.fa-usd {
  color: #2ab8f5;
}

.fa-list-ul {
  color: #2ab8f5;
}

.fa-users {
  color: #2ab8f5;
}

.report-back {
  background-color: transparent;
  border: 1px solid #2ab8f5;
  color: #2ab8f5;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.profileCard {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.orderBtn {
  text-align: center;
  border: 1px solid #2ab8f5;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.orderBtnM {
  text-align: center;
  border: 1px solid #2ab8f5;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.successContainer {
  width: 80%;
  /* border: 1px solid #CCC; */
  border-radius: 5px;
  padding: 10px;
  margin-right: -25px;
}

.reportPic {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
  border: 1px solid white;
  border-radius: 50%;
}

.report-table-title {
  text-align: left;
  font-weight: bold;
}

.report-table-data {
  text-align: left;
}

.back-to-gene {
  margin-left: 20px;
  margin-top: 20px;
}

.report-btn {
  background-color: white;
  color: #2ab8f5;
  border-color: #2ab8f5;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-bottom: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

.report-dropdown {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-bottom: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

.report-facet {
  position: absolute;
  padding-top: 4px;
  padding-left: 4px;
}

.details-table {
}

.details-table > tbody > tr {
  border: 1px solid #ccc;
}

.details-table > tbody > tr > td:first-child {
  border-right: 1px solid #ccc;
}

.report-user-view-table-col {
  padding: 0px;
}

.report-user-view-table > thead > tr {
  border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr {
  border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr > td {
  border-right: 1px solid #ccc;
}

.detail-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 20px;
}

.hr-style {
  border: 0;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 15px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #2ab8f5,
    rgba(0, 0, 0, 0)
  );
}

.font-style {
  font-weight: bolder;
}

.report-inline-text {
  display: inline;
  margin: 0px;
}

.inline-text-table {
  display: inline;
  margin: 0px;
}

.dropdown-items {
  cursor: pointer;
  background-color: white;
}

.dropdown-items a:hover {
  background-color: #f5f5f5 !important;
}

.report-select-text {
  margin-top: 4px;
}

.detail-report-user-table > tbody {
  width: 100%;
}

/* .detail-report-user-table>tbody>tr>td {
} */

.detail-report-user-table > tbody > tr > td:nth-child(odd) {
  font-weight: bold;
}

.qs-row .container {
  width: auto;
}

/* .detail-report-user-table > tbody > tr:nth-child(odd){
    background-color: #F2F2F2;
} */

.order-button-report {
  border: 1px solid;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 230px;
  display: block;
  margin: 0 auto;
}

.global-connected-button-left {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 5px 47px;
  cursor: pointer;
  max-width: 100% !important;
}

.global-connected-button-middle {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  padding: 5px 20px;
  cursor: pointer;
}

.global-connected-button-right {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 47px;
  cursor: pointer;
  max-width: 100% !important;
}

.global-connected-button-active {
  background-color: #2ab8f5;
  border: 1px solid #2ab8f5;
  color: white;
}

.next-btn.disabled,
.next-btn:disabled {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.input-spinner {
  position: absolute;
  top: 45px;
  right: 50px;
}

.form-control.alert,
.form-control:disabled {
  border-radius: 44px;
}

/*.page-title h1,
.page-title h2,
.page-title h3 {
     margin-left: 25px; 
}*/

.page-title h1 {
  width: 100% !important;
  color: black !important;
  max-width: 100% !important;
  font-weight: 400 !important;
}

.nav-link {
  cursor: pointer;
}

.circle-75 {
  color: #2bd395;
  padding-top: 25px;
  font-weight: bold;
  font-size: 40px;
}

.switch-shop {
  cursor: pointer;
  font-weight: bold;
  width: auto;
}

.switch-shop.active {
  border-bottom: 1px solid #0da9ef;
}

/** End of utilities */

/** Top Navigation */

.route-wrapper .topbar {
  min-height: 40px;
}

/** End of top navigation */

/** Left Side Navigation */

.hamburger-container {
  width: 74px !important;
}

.main-menu {
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.offmenu {
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.menu-cell {
  width: 100%;
  height: 50px;
  display: table !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  cursor: pointer;
}

.menu-inner-cell {
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}

.hello-fa {
  margin-left: 20px;
}

.hello-name {
  font-weight: bold;
  display: inline;
  font-size: 16px;
}

.hello-user {
  margin-left: 10px;
  font-weight: normal;
  display: inline;
  font-size: 16px;
}

.side-bar-login {
  margin-left: 20px;
  font-size: 16px;
}

.active-sidebar {
  cursor: pointer;
  color: #0da9ef;
}

.caret-container,
.menu-inner-cell {
  display: table-cell;
  vertical-align: middle;
}

.caret-container {
  text-align: center !important;
}

.caret-cell,
.menu-cell {
  height: 50px;
  display: table;
}

.menu-cell {
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
  cursor: pointer;
}

.caret-cell {
  border-left: 1px solid hsla(0, 0%, 100%, 0.12);
  width: 50px;
  float: right;
}

.menu-container,
#menu-container {
  background-color: #374250;
  -webkit-overflow-scrolling: touch;
  color: white;
  width: 250px;
  margin-top: 0px;
}

.topbar {
  background-color: #f5f5f5;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  top: 0;
  bottom: 0;
  z-index: 998 !important;
  padding-right: 15px;
  padding-left: 15px;
}

.slick-prev {
  /* left: 15px !important; */
  width: 20px;
  height: 42px !important;
  padding-right: 40px !important;
  display: block;
  height: 100% !important;
  padding-left: 20px !important;
  left: 0px !important;
}

.slick-prev::before {
  left: 15px !important;
  font-size: 40px !important;
}

.slick-next {
  right: 0px !important;
  width: 20px;
  height: 42px !important;
  display: block;
  height: 100% !important;
  padding-right: 30px !important;
  padding-left: 20px !important;
  margin-right: 30px !important;
}

.slick-next::before {
  right: 15px !important;
  font-size: 40px !important;
}

.route-wrapper {
  z-index: 2;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}

/** End of left side navigation */

/** Login */

.lang-currency-switcher-wrap .dropdown-menu {
  /* left: -60px; */
  padding-bottom: 12px;
  overflow: hidden;
  width: 219px;
  z-index: 1001;
  top: 30px;
  display: block;
}

/** End of login */

/** Header */

.navbar {
  min-height: 78px !important;
}

.name-header {
  font-size: 14px !important;
  color: #0da9ef !important;
  padding-top: 10px !important;
}

.site-logo {
  padding: 10px 20px 2px 0px;
  cursor: pointer;
}

.site-logo > img {
  margin-left: 3px;
  margin-top: -4px;
  width: 120px !important;
  max-width: none;
}

.site-logo {
  padding-left: 24px;
}

.download-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.offcanvas-toggle {
  border-right: 0px;
  position: relative !important;
  width: 50px;
  height: 50px;
}

.offcanvas-toggle {
  width: 74px;
  margin-right: 20px;
}

.box-center {
  text-align: center;
}

.box-center-inner {
  margin: 0 auto;
}
.navbar.navbar-sticky {
  position: -webkit-sticky;
  position: sticky !important;
}
.navbar.navbar-stuck {
  width: 100%;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky !important;
}

.site-search {
  left: 0;
  top: 0px;
}

.route-wrapper.navbar {
  min-height: 78px;
}

.navbar.navbar-stuck {
  right: 1em;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
}

.navbar {
  z-index: 997 !important;
}

.site-branding {
  height: 77px;
  overflow-y: hidden;
}

.lang-currency-switcher-wrap {
  /* margin-right: 20px; */
  margin-left: 0px;
}

.lang-currency-switcher-wrap .lang-currency-switcher.login {
  margin-top: -7px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .language {
  width: 40px;
  margin-top: -5px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .currency {
  margin-top: 0px;
}

.lang-currency-switcher-wrap .currency-select {
  padding-right: 26px;
}

.language-title {
  margin-left: 20px;
  margin-bottom: 10px;
}

/* Home */

.remove-from-cart {
  color: #ff5252 !important;
  cursor: pointer;
}

.clear-cart-btn:hover {
  color: white !important;
}

.warning-checkout {
  color: red;
}

.viewprod {
  height: 40px !important;
  font-weight: bold;
}

.logo-product {
  height: 70px;
}

.under-logo-title {
  font-size: 30px;
  font-weight: bold;
}

.single-product-case {
  border: 1px solid #e1e7ec;
  border-radius: 5px;
}

.single-product-case > img {
  border-radius: 5px;
  height: auto;
}

.single-product-title {
  font-size: 30px;
  color: black;
}

.single-product-price {
  font-size: 30px;
  color: black;
  font-weight: 500;
}

.service-img {
  /*border: 1px solid #e1e7ec;*/
  width: 100px;
  /*border-radius: 50px;*/
  margin: 0px auto;
  margin-top: 10px;
}

.uniBanner {
  background: url(./assets/bg_test.jpg) 20% 0% / cover !important;
}

.omegaBanner {
  background: url(./assets/omega_bg.jpg) 47% 0% / cover !important;
}

.biosBanner {
  background: url(./assets/bg_bios.png) 47% 0% / cover !important;
}

.hipster-dude {
  background-image: url("http://themes.rokaux.com/unishop/v1.3/img/banners/home01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.limited-offer {
  color: #ff5252 !important;
  font-weight: 500;
}

.offer-left {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.pop-brands {
  background-color: #f5f5f5;
}

.all-products {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #e1e7ec !important;
  border-radius: 20px;
  height: 40px;
  min-width: 150px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 20px;
}

.product-card {
  height: 100%;
}

.suggested-slider .product-card {
  height: auto;
}

.suggested-slider .product-card .product-thumb {
  width: 100%;
  /* height: 200px; */
}

.suggested-slider-mobile .product-card {
  height: auto;
}

.suggested-slider-mobile .product-card .product-thumb {
  width: 100%;
  /* height: 200px; */
}

/* hiding all bullets by default */

.slick-slider.suggested-slider
  .slick-dots
  li
  + li
  + li
  + li
  + li
  + li
  + li
  + li {
  display: none !important;
}

.slick-slider.suggested-slider-mobile .slick-dots li + li + li + li + li {
  display: none !important;
}

/* only displaying the active bullets and the 2 bullets next to it */

/* .slick-slider.suggested-slider .slick-dots li.slick-active,
.slick-slider.suggested-slider .slick-dots li.slick-active + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li + li
 {
    display: inline-block!important;
} */

/* displaying the last three bullets when slick-active class isn't applied to any li before them  */

/* .slick-slider.suggested-slider .slick-dots li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(4) {
    display: inline-block!important;
} */

/* hiding the last three bullets if slick-active exist before them */

/* .slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(4) {
    display: none!important;
} */

/* specific conditions to always display the last three bullets */

/* .slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(1){
    display: inline-block!important;
} */

.product-card .product-thumb {
  margin-bottom: auto;
  margin: auto;
}

.product-image {
  display: flex;
  margin: auto;
  height: 220px;
  align-items: center;
}

.product-card .product-thumb img {
  width: auto;
  margin: auto;
}

.productImage {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 200px;
}

.cart-img {
  width: 160px;
  text-align: center;
  padding-left: 5px;
}

.cart-img > img {
  width: auto;
  max-width: 110px;
  height: auto;
  max-height: 90px;
}

.small-right-product {
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.small-right-product > img {
  height: auto;
  max-height: 80px;
}

/*
.product-thumb {
    height: 220px;
}*/

.top-cat-cell > .container-fluid {
  height: 209px;
}

.top-cat-cell > .container-fluid > .row {
  height: 100%;
}

.border-right {
  width: 100%;
}

.left-prod-cat {
  margin: auto;
  vertical-align: middle;
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.left-prod-cat img {
  height: auto;
  max-height: 179px;
}

.padding-top-10 {
  padding-top: 12px;
}

.right-prod-cat {
  width: 125px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
}

.right-prod-cat > img {
  border-radius: 7px;
  max-width: 94px;
  max-height: 83px;
}

.top-cat-cell {
  border-radius: 7px;
  border: 1px solid #e1e7ec;
  margin-bottom: 20px;
}

.view-product-btn {
  width: 50% !important;
}

.left-prod-cat > img {
  border-radius: 7px;
}

.cat-shop {
  font-weight: 500;
}

.input-range__track--active {
  background-color: #9da9b9 !important;
}

/* .input-range__slider {
    background: white !important;
    border: 4px solid #9da9b9 !important;
} */

.input-range__slider {
  height: 95px;
  background: white !important;
  border: 4px solid #9da9b9 !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
  margin-top: -1rem !important;
  margin-left: -0.8rem !important;
}

.input-range__label-container {
  line-height: 7rem !important;
  font-size: 1.25rem !important;
  font-weight: 300 !important;
}

.range-slider-container {
  margin-right: 20px;
  margin-left: 20px;
}

.slick-slide {
  background-size: cover !important;
  height: 500px !important;
}

.shopping-cart .product-button {
  padding-bottom: 8px;
}

.shopping-cart td.product-detail {
  padding-left: 0px;
}

@media (max-width: 720px) {
  .slick-slide {
    height: 320px !important;
  }
}

.slides-spinner {
  width: 100%;
  height: 580px;
}

.gallary-slides {
  width: 100%;
  height: 500px;
}

.img-container {
  background-image: url(./assets/main-bg.jpg);
  width: 100%;
  height: 580px;
  background-position: center;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.sliderImg {
  width: auto;
  height: 315px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
  top: 27px;
}

.sliderImgBios {
  width: auto;
  height: 220px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
  top: 55px;
}

.sliderImgUni {
  width: auto;
  height: 220px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
}

.title-pos {
  position: relative;
  top: 80px;
}

.label-pos {
  position: relative;
  top: 60px;
}

.button-pos {
  position: relative;
  top: 90px;
}

.slider-title {
  font-size: 25px;
}

.slider-label {
  font-family: "Maven Pro", Helvetica, Arial, sans-serif;
}

.slider-button-label {
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
  background: linear-gradient(to bottom right, rgb(58, 129, 88), #92d153);
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.slider-button-label-2 {
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
  background: linear-gradient(to bottom right, #d64c4e, rgb(69, 50, 119));
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

/* .gallary-img{
    display: flex;
    flex: 0.5;
    justify-content: flex-start;
    align-items: center;
}
.gallary-btn{
    flex-direction: column;
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: flex-start;
}*/

.height100 {
  height: 100%;
}

.gallary-cells {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.gallary-cell-bottom-image {
  max-height: 270px !important;
}

.info-button > img {
  border: 1px solid #e1e7ec;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: gray;
  padding: 7px;
  /* position: absolute; */
  /* right: calc(50% + 65px); */
}

.info-button-currency-rate > img {
  border: 1px solid #e1e7ec;
  border-radius: 16px;
  width: 22px;
  height: 22px;
  color: gray;
  padding: 4px;
  /* position: absolute; */
  /* right: calc(50% + 65px); */
}

.ant-tooltip-inner {
  color: black;
  background-color: #f2f2f2;
}

.width-full {
  width: 100%;
}

.width-full-currency-rate {
  width: 100%;
  margin-right: 30px;
}

.info-button > svg {
}

.info-button-detail > img {
  border: 1px solid #e1e7ec;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: #ccc;
  padding: 7px;
}

.qtyplusminusbtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 44px;
  overflow: hidden;
  padding: 0px 20px;
  /*position: relative;*/
}

/*.qtyplusminusbtn:hover{
    background-color: #48A8E9;
}*/

.borderRight {
  border-right: 1px solid #e1e7ec;
}

.borderLeft {
  border-left: 1px solid #e1e7ec;
}

.qtyInput {
  border-width: 1px;
  border-style: solid;
  border-color: #e1e7ec;
  border-radius: 5px;
}

.singleProduct .qtyInput {
  margin-right: 0px;
}

.qtyInput .form-control-number-new.x {
  border-radius: 5px;
  text-align: center;
}

.form-control-number-new {
  width: 50px;
  height: 44px;
  /*margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 5px 3px;
    border: 1px solid #dbe2e8;
    border-radius: 22px;*/
  border: none;
  overflow: hidden;
  background-color: #fff;
  color: #606975;
  font-family: Maven Pro, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.shopping-cart .qtyInput .form-control-number-new {
  border-radius: 5px;
}

.QtyDetail button {
  position: relative;
  top: 2px;
  height: 40px;
  /* width: 40px; */
  width: 32%;
  max-width: 40px;
  border-radius: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
  border-color: #e1e7ec;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid #e1e7ec;
}

.QtyDetail button:hover {
  border-color: #48a8e9;
}

.QtyDetail input {
  border-radius: 0px;
  height: 40px;
  border-left: 0px;
  border-right: 0px;
  padding-bottom: 5px;
}

.QtyDetail button > i {
  position: relative;
  right: 5px;
  color: #303a46;
}

.QtyDetail span:first-child button {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.QtyDetail span:last-child button {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.plus-minus {
  color: #424749 !important;
  background-color: white !important;
  border: 1px solid #424749 !important;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.input-detail {
  color: #757777 !important;
  background-color: white !important;
  border: 1px solid #757777 !important;
  border-radius: 5px;
  height: 30px;
  width: 40px;
  text-align: center;
}

.detail-image {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.add-to-cart {
  color: #0da9ef !important;
  background-color: white !important;
  border: 1px solid #0da9ef !important;
  border-radius: 16px;
  padding: 5px 15px 5px 15px;
  min-width: 120px;
  margin-left: 10px;
  cursor: pointer;
}
.product-buttons-kr .add-to-cart {
  margin-left: 0px;
  width: 135px;
}
.add-to-cart.new-info-button {
  color: grey !important;
  /* margin-bottom: 12px; */
  display: block;
  border: 1px solid #e1e7ec !important;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 12px;
}

a.add-to-cart.new-info-button:hover {
  text-decoration: none;
}

.out-of-stock {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 16px;
  height: 32px;
  width: 132px;
  margin-left: 10px;
}
.product-buttons-kr .out-of-stock {
  width: 135px;
  margin-left: 0px;
}
.qs-row {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 30px;
  padding-bottom: 3px;
  padding-top: 3px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  box-shadow: 7px 8px 20px -10px rgba(47, 56, 68, 0.22);
}

.qs-title {
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}

.qs-add-to-cart {
  color: #0da9ef !important;
  background-color: white !important;
  border: 1px solid #0da9ef !important;
  border-radius: 16px;
  height: 32px;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.qs-out-of-stock {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 16px;
  height: 32px;
  width: 100%;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.expand-row {
  height: 20px;
  margin-left: 20px;
  font-size: 20px;
  color: green;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.view-ba-detail {
  font-weight: 400;
}

.expand-btn {
  padding-bottom: 10px;
  width: 100%;
}

.alpha-down {
  margin: 0px;
  display: inline;
  margin-left: 10px;
  color: black;
  font-size: 17px;
}

.qs-quicknav {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  height: calc(100% - 77px);
  padding-top: 50px;
  z-index: 100;
}

.hidden-remove {
  margin-top: 10px;
}

.qsoption {
  text-align: center;
  cursor: pointer;
}

.qsoption:hover {
  color: #0da9ef;
}

.header-cart {
  margin-top: 12px;
  width: 18px;
  margin-right: 5px;
}

.basket-spin {
  color: #0da9ef;
}

.cart-div {
  float: left;
}

.cart-info {
  margin-top: 8px;
  float: left;
}

.cart-info-qty {
  margin-top: 13px;
  float: left;
}

.cart-text {
  font-size: 12px;
  text-align: left;
  line-height: 13px;
}

.blue-man {
  color: #0da9ef;
}

.new-profile-login {
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.hovbox-name {
  margin: 0px;
}

.hovbox-rank {
  color: #9da9b9;
}

.lessPadding {
  padding: 3px !important;
}

.after-login-hover-menu {
  width: 215px;
  background: white;
  position: fixed;
  /*right: 20px;*/
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1000;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.new-login {
  width: 250px;
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.new-login-ie {
  top: 103px !important;
}

.success-img-ie {
  padding-top: 20px;
  margin: 0 auto;
  max-width: 130px !important;
}

.new-login::selection {
  display: none;
}

.no-blue-highlight:active {
  color: inherit !important;
  background-color: white !important;
}

.no-blue-highlight:hover {
  border-radius: 5px;
  color: inherit !important;
  background-color: white !important;
}

/** End of Header */

/** Enroll */

.referral-link {
  text-align: center;
  margin-top: 10px;
  border: 1px solid #e1e7ec;
  width: 220px;
}

.generated-link {
  background-color: white;
  border: 1px solid #e1e7ec;
  color: #0da9ef;
}

.generated-link:hover {
  background-color: #f2f2f2;
}

.enrollment-page {
  min-height: 50vh;
}

.enroll-btns {
  transition: all 1000ms;
  width: 220px;
  height: 40px;
  font-weight: 500;
}

.quick-shop {
  margin-bottom: 80px;
}

.quick-shop .container {
  width: 100%;
}

.quick-shop .widget-categories > ul > li {
  cursor: pointer;
}

.quick-shop .widget-categories > ul > li > a {
  width: 100%;
  height: auto;
  padding: 15px;
}

.product-detail .widget-categories > ul > li > a:hover,
.quick-shop .widget-categories > ul > li > a:hover {
  background: #f2f2f2;
}

.quick-shop .widget-categories > ul > li > ul {
  margin-left: 40px;
}

.quick-shop .widget-categories > ul > li::before {
  content: unset !important;
  position: absolute;
  left: 0px;
}

.quick-shop .widget-categories > ul > li > ul > li .row {
  margin-top: 20px;
}

.quick-shop .btn-success,
.quick-shop .btn-success:hover,
.product-detail .btn-success,
.product-detail .btn-success:hover {
  background: #0da9ef !important;
}

.quick-shop .input-group .input-group-btn button,
.product-detail .input-group .input-group-btn button {
  font-size: 12px;
  color: white;
}

.quick-shop .btn-danger,
.product-detail .btn-danger {
  background: #c1c1c1 !important;
  border: 1px solid #c1c1c1;
}

.enroll-form {
  min-height: 400px;
}

.enroll-form .quick-shop {
  margin-top: 60px;
}

.quick-shop .input-group-btn {
  margin-top: 20px;
}

.quick-shop .input-group .input-group-addon,
.input-group .input-group-btn {
  position: unset;
}

.product-detail .input-group .input-group-btn {
  padding-top: 15px;
  color: #ffffff;
}

.product-detail .input-group .input-group-btn button {
  background: #000;
}

.widget-categories ul > li > ul > li .row .col-8 > a {
  border-left: 0px;
}

.widget-categories ul > li {
  float: left;
}

.page-header h6 {
  font-size: 28px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.summary {
  list-style: none;
  padding-top: 20px;
}

.summary span {
  color: rgb(157, 157, 157);
}

.summary p {
  font-weight: bold;
  font-size: 20px;
}

.container.container-summary {
  width: 1200px;
}

.widget-categories ul > li::before,
.widget-links ul > li::before {
  content: unset;
}

.product-detail {
  margin-top: 25px;
}

/** End of enroll */

/** Genealogy */

.gene-result-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.no-match {
  color: red;
}

.no-bookmark {
  color: red;
  margin-bottom: 15px;
}

.gene-close-search {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 30px;
  background: #ff5152;
  color: white;
  border: 0px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

.gene-result-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.gene-result-item {
  text-decoration: none;
}

.time-gene {
  font-size: 14px;
  margin-left: 10px;
}

.new-left-cell {
  display: flex;
  flex-direction: row;
}

.new-left-inner {
  height: 50px;
  overflow-x: hidden;
}

.new-left-name {
  font-size: 13px;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.new-left-name:hover {
  color: #57bafc;
}

.new-left-name::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  display: none;
  /* optional: just make scrollbar invisible */
}

.new-left-name::-webkit-scrollbar-thumb {
  background: transparent;
  display: none;
}

.new-left-arrow {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.address-table {
  width: 100%;
  margin: auto;
  border: 0px solid #e1e7ec;
}

.address-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.address-table > thead {
  border-top: 1px solid #e1e7ec;
  border-bottom: 2px solid #e1e7ec;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.address-table > tbody > tr {
  border-bottom: 1px solid #e1e7ec;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.address-table > thead > tr > th {
  width: 30%;
  text-align: center;
  color: #2f9ce6;
  font-size: 15px;
  padding: 5px;
}

.address-table > tbody > tr td {
  text-align: left;
  padding: 4px 0px 0 4px;
}

.address-table > tbody > tr td > i {
  cursor: pointer;
  padding: 15px;
}

.address-table .name,
.address-table .address {
  text-align: left;
}

.address-table .edit {
  margin: 0 auto;
}

.address-table .delete {
  width: 12%;
}

.vl {
  border-left: 1px solid #e1e7ec;
  height: 70%;
  vertical-align: middle;
  position: absolute;
  left: 40%;
  top: 15%;
}

.lsb-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
  border-radius: 5px;
  perspective: 1px;
  border-collapse: separate;
}

.lsb-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lsb-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.lsb-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.lsb-table > tbody > tr > td:first-child {
  color: #49a8e9;
  font-weight: bold;
  text-align: center;
}

.lsb-table > tbody > tr > td:last-child {
  text-align: center;
}

.lsb-data {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
  border-collapse: separate;
  border-radius: 7px;
  border-spacing: 0px 10px;
}

.lsb-data > thead > tr {
  color: #ffffff;
  -webkit-box-shadow: 0px 5px 20px -10px #a3a3a3;
  -moz-box-shadow: 0px 5px 20px -10px #a3a3a3;
  box-shadow: 0px 5px 20px -10px #a3a3a3;
}

.lsb-data > thead > tr > th {
  position: relative;
  bottom: 5px;
}

.order-detail-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.order-detail-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.order-detail-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-detail-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.order-detail-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.order-detail-table > tbody > tr > td:first-child {
  /* cursor: pointer; */
  color: #0da9ef;
  font-weight: bold;
}

.order-detail-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.order-detail-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.kpi-detail-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.kpi-detail-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.kpi-detail-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.kpi-detail-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.kpi-detail-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.kpi-special-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.kpi-special-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.kpi-special-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.kpi-special-table > tbody > tr > td:first-child {
  /* cursor: pointer; */
  color: #0da9ef;
  font-weight: bold;
}

.kpi-special-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.kpi-special-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.close-modal-btn {
  position: absolute;
  right: -15px;
  top: -10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.genealogy-modal-button {
  width: 250px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.genealogy-option-modal-top {
  height: 70vh;
}

.genealogy-option-modal-bottom {
  padding: 10px;
  padding-bottom: 20px;
  background: white;
  width: 400px;
  margin: 0 auto;
  border-top: 1px solid #ccc;
  box-shadow: 0px 0px 15px 0px #ccc;
}

.genealogy-option-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  left: 0;
  padding-top: 40vh;
  background-color: rgba(255, 255, 255, 0.2);
}

.detail-star-empty {
  font-size: 17px;
}

.detail-star {
  font-size: 17px;
  color: gold;
}

.refreshBtn {
  margin-right: 5px;
}

.refresh-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.search-box {
}

.sort-by-ov {
  background-color: #f5f5f5;
  color: gray;
  border: 1px solid #e1e7ec;
  position: relative;
  top: 1px;
  width: auto;
}

.sort-by-ov.hidden-sm-down {
  top: 0px;
}

.sort-by-ov-active {
  background-color: #2ab8f5;
  border: 1px solid #2ab8f5;
  color: white;
}

.detail-save-btn {
  display: inline;
  padding: 0 0 0 3px;
  color: #0000ff;
}

.bookmark-cells {
  color: #0da9ef;
  margin-left: 15px;
}

.fav-person {
  cursor: pointer;
}

.left-name {
  color: #606975;
  cursor: pointer;
  float: left;
}

.left-name:hover {
  color: #0da9ef;
}

.modal-header {
  justify-content: center !important;
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
  padding-left: 30px;
  padding-right: 30px;
}

.modal-dialog {
  width: auto !important;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 668px !important;
  }
  .overwrite-flex {
    flex-direction: column !important;
  }
}

@media (max-width: 992px) {
  .overwrite-flex {
    flex-direction: column !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .overwrite-flex {
    flex-direction: column !important;
  }
}

.checkout-button {
  background-color: white;
  color: black;
  border: 1px solid #dce2e8;
  padding: 60px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.genealogy-bookmark-modal-bottom {
  overflow: scroll;
  background: white;
  padding: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px 0px #ccc;
  z-index: 2001;
}

.genealogy-bookmark-modal {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  z-index: 2000;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.empty-modal-top {
  height: 10%;
}

.hide-box {
  height: 50px;
  border: 0px;
  display: table;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.hide-box:hover {
  color: #0da9ef !important;
}

.hide-box-inner {
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
}

.main-row {
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.select-level-text {
  display: inline;
  margin-right: 10px;
}

.genealogy-select {
  border: 1px solid #ccc;
}

.genealogy-select-container {
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.genealogy-page-wrapper,
.global-container-bottom-padding {
  padding-left: 5px;
  padding-right: 5px;
  background: white;
  padding-bottom: 20px;
  min-height: 500px;
}

.col-shadow {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 0px 5px -1px #ccc;
  z-index: 1;
  background-color: transparent;
}

.left-cell {
  height: 49px;
  text-align: center;
}

.lvl-cell {
  display: table;
  width: 100%;
  font-size: 10px;
  cursor: pointer;
}

.left-cell-font:hover {
  color: #0da9ef !important;
}

.left-cell-font {
  color: #606975;
  font-weight: bold;
  font-size: 15px;
}

.left-cell-row {
  height: 50px;
  border-bottom: 1px solid #ccc;
}

.top-thead {
  border-top: 0px !important;
}

.linearBG {
  background-color: #374250 !important;
  color: white !important;
}

.cool-background {
  color: #606975;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
}

.gene-search {
  width: 100% !important;
  height: 32px;
  border: 1px solid #e1e7ec;
  border-radius: 15px;
  outline: none;
  padding-left: 15px;
  padding-right: 30px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.smaller-text {
  font-size: 12px;
  height: 33px;
  max-width: 100px;
}

.gene-search-button {
  cursor: pointer;
  border: 0px;
  background: transparent;
  height: 36px;
  width: 32px;
  position: absolute;
  margin-left: -50px;
  margin-right: 10px;
}

.gene-search-button > i {
  margin-bottom: 3px;
}

.vertical-mid {
  vertical-align: middle;
  display: table-cell;
  padding-left: 5px;
  text-align: left;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
}

.name-cell {
  padding: 8px;
}

.genealogy-table {
  width: 100%;
  margin: 0px;
  font-size: 12px;
}

.genealogy-table > thead > tr > th:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(5),
.genealogy-table > tbody > tr > td:nth-child(8),
.genealogy-table > thead > tr > th:nth-child(5),
.genealogy-table > thead > tr > th:nth-child(8) {
  border-right: 1px solid #ccc;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(6),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(9),
.genealogy-table.hasVIP > thead > tr > th:nth-child(6),
.genealogy-table.hasVIP > thead > tr > th:nth-child(9) {
  border-right: 1px solid #ccc;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(5),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(8),
.genealogy-table.hasVIP > thead > tr > th:nth-child(5),
.genealogy-table.hasVIP > thead > tr > th:nth-child(8) {
  border-right: 0px;
}

.expand-icon {
  font-size: 22px;
}

.button-margin-left {
  margin-left: 10px !important;
}

.expand-icon-container {
  height: 100%;
  text-align: right;
  float: left;
}

.genealogy-table > tbody > tr > td:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table > thead > tr > th {
  height: 50px;
  text-align: center;
  color: #606975;
  font-weight: bold;
  font-size: 15px;
  border: 0px;
  border-bottom: 1px solid #ccc;
  background: white;
}

.genealogy-table > thead > tr:nth-child(2) > th {
  text-align: center;
}

/* .genealogy-table>thead>tr>th:hover {
    /* color: #0da9ef !important; 


}*/

.genealogy-table > tbody > tr > td {
  width: 8.333333%;
  height: 50px;
  text-align: center;
  border-right: 0px;
  border-left: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
}

.genealogy-table-col {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.genealogy-table-col::-webkit-scrollbar {
  display: none;
}

.lastCSS {
  border-bottom: 0px;
  height: 49px;
}

.rankSelect {
  position: relative;
}

.centerSelect {
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  align-content: center;
}

/** End of genealogy */

/* LSB Tab */

.lsb-box,
.orderhistory-box,
.commission-box {
  border: 1px solid #e1e7ec;
  border-radius: 7px;
  padding: 60px 5px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.table-lsb > td {
  vertical-align: middle;
}

.col-head-title {
  background-color: #606975;
  color: #ffffff;
  padding: 5px !important;
  border: 0px !important;
}

.row-col {
  font-size: 15;
  align-content: top;
}

.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
}

.nohover tbody tr:hover {
  background: none;
}

.small-12 {
  width: 100%;
}

.columns {
  border: 0px solid #000000;
}

.table-responsive > .table-bordered {
  border: 0;
}

/* End of LSB Tab */

/* Commission Statement */

.commission-table {
  width: 100%;
  border-left: 1px solid #ccc;
}

.commission-table > tbody > tr {
  border-bottom: 1px solid #ccc;
}

.commission-table > tbody > tr > td {
  text-align: center;
  border-right: 1px solid #ccc;
}

.select-state {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 50%;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 17px;
  background: #f8f8f8;
  padding: 0px;
  display: block;
}

.table-head {
  text-align: center;
  color: #606975;
  vertical-align: middle;
  font-size: 13px;
  vertical-align: middle;
}

.button-select {
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
}

/* Kpitable */

.kpi-table-header {
  background-color: #606975;
  height: 20px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  color: white;
  text-align: center;
}

.kpi-table-borby {
  text-align: center;
}

.suggested-slider .slick-slide {
  padding: 8px !important;
}

.suggested-slider-mobile .slick-slide {
  padding: 8px !important;
}

/* tr:nth-of-type(even) {
    background-color: #dddddd;
} */

td,
th {
  font-size: 12px;
  text-align: left;
  padding: 8px;
}

.shopping-cart td,
.shopping-cart th {
  font-size: 14px;
}

table {
  width: 100%;
  align-content: center;
  margin-bottom: 15px;
}

/* Profile */

.profile-link {
  cursor: pointer;
}

.user-info-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -1px;
  padding-top: 90px;
  padding-bottom: 0px;
  border: 1px solid #e1e7ec;
  border-radius: 7px;
}

.user-cover {
  background-image: url(./assets/profileBG.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-position: center;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #e1e7ec;
}

.info-label {
  display: block;
  position: absolute;
  top: 18px;
  right: 18px;
  height: 26px;
  padding: 0 12px;
  border-radius: 13px;
  background-color: #fff;
  color: #606975;
  font-size: 12px;
  line-height: 26px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
  cursor: default !important;
}

.user-info {
  display: table;
  position: relative;
  width: 100%;
  padding: 0 18px;
  z-index: 5;
  margin-bottom: 20px;
}

.single-product {
  max-height: 300px;
}

.user-info-sm {
  display: table;
  /*position: relative;*/
  width: 100%;
  /*padding-top:25px;*/
  /*padding: 0 18px;*/
  z-index: 5;
  margin-bottom: 5px;
}

.user-avatar {
  position: relative;
  width: 115px;
  display: table-cell;
  vertical-align: top;
  border-radius: 100px;
}

.user-avatar-sm {
  position: relative;
  width: 65px;
  display: table-cell;
  /*vertical-align: top;
    border-radius: 100px;*/
}

.user-avatar > img {
  background-color: white;
  display: block;
  width: 100%;
}

.user-data {
  padding-top: 48px;
  padding-left: 12px;
  display: table-cell;
  vertical-align: top;
}

.user-data-sm {
  padding-top: 10px;
  padding-left: 8px;
  margin-right: 5px;
  /*margin-top20px;
    padding-top: 10px;
    padding-left: 5px;*/
  display: table-cell;
  vertical-align: middle;
}

.user-data > span {
  display: block;
  color: #9da9b9;
  font-size: 13px;
}

.form-group label {
  font-size: 13px;
  font-weight: 500;
  cursor: default;
}

/*Success*/

.success-row {
  margin-top: 10px;
}

.success-badge {
  margin-right: 2px;
}

.rank-short-success {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-row {
  display: inline-block;
}

.rank-name {
  display: none;
}

.rank-pin {
  cursor: pointer;
}

.rank-pin > p {
  position: relative;
  border: 1px solid #e1e7ec;
  border-radius: 500px;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.rank-row .gallary-container {
  height: auto;
}

.success-progress-bar {
  margin-top: 5px;
  height: 20px;
  border-radius: 7px;
  background-color: #f0f0f0;
}

.success-progress-bar-inner {
  transition: all 400ms ease 0ms;
  height: 20px;
  border-radius: 5px;
  background-color: #0da9ef;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  position: relative;
  bottom: 21px;
}

.leg-choice {
  margin-left: 10px;
  margin-right: 10px;
}

.success-table-container {
  margin-left: -5px;
  margin-right: -5px;
}

.status-page.months-select li {
  display: inline-block;
  padding: 0 20px;
}

.status-button {
  background: none;
  border: 0px;
  border-bottom: 2px solid #f0f0f0;
  padding: 0px 50px;
  cursor: pointer;
}

.status-button.active {
  border-bottom-color: rgb(255, 183, 79);
}

.status-button-commission {
  background: none;
  border: 0px;
  border-bottom: 2px solid #f0f0f0;
  padding: 0px 50px;
  cursor: pointer;
}

.status-button-commission.active {
  border-bottom-color: #48a8e9;
}

.btn-leg {
  padding: 2px 10px;
  padding-bottom: 23px;
  height: 20px;
  color: white;
  text-decoration: none;
  border-radius: 7px;
  position: relative;
  top: 2px;
  cursor: pointer;
  border: 0px;
  right: 5px;
}

.align-stuff {
  text-align: center;
}

.rank-badges .slick-prev:before {
  font-family: "FontAwesome";
  content: "\f104" !important;
}

.rank-badges .slick-next:before {
  font-family: "FontAwesome";
  content: "\f105" !important;
}

.btn-leg:hover {
  color: #cccccc;
}

.btn-leg.leg1 {
  background-color: rgb(80, 198, 233);
}

.btn-leg.leg2 {
  background-color: rgb(255, 183, 79);
}

.btn-leg.leg3,
.btn-leg.leg3hc,
.btn-leg.ov_leg3hc {
  background-color: rgb(255, 82, 82);
}

.btn-leg.apt {
  background-color: #a6ca72;
}

.col-11.success-fix-width {
  flex: 0 0 95%;
  max-width: 95%;
  padding-right: 0;
}

.col-1.btn-fix-width {
  flex: 0 0 5%;
  max-width: 5%;
}

.dateMargin {
  margin-top: 20px;
}

.id-label {
  display: block;
  position: absolute;
  top: 48px;
  right: 33px;
  height: 26px;
  padding: 0 12px;
  color: #606975;
  font-size: 12px;
  line-height: 26px;
}

/** Forms */

.form-control-number {
  width: 50px;
  height: 44px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0 5px 3px;
  border: 1px solid #dbe2e8;
  border-radius: 22px;
  background-color: #fff;
  color: #606975;
  font-family: Maven Pro, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 22px;
  border-top-right-radius: 22px;
}

.input-group .form-control {
  width: 100%;
}

.feedback-box {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  text-align: left;
  padding: 0 18px 3px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: #dfdfdf;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  resize: none;
}

.feedback-response p {
  font-size: 15px;
}

.input-box {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  text-align: left;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: #dfdfdf;
  font-size: 14px;
  resize: none;
}

/** Spinkit */

.sk-circle {
  width: 60px;
  height: 60px;
  position: relative;
  color: #48a8e9;
  top: 122px;
  z-index: 6;
  left: 45px;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #48a8e9;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.arrow {
  text-align: center;
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0da9ef;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/** End of Spinkit */

/* New Slider */

.slider-mobile button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  /*transition: all 0.5s ease;*/
  border: none;
  background: #0d9fed;
  border-radius: 5px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: white;
}

.inner button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  /*transition: all 0.5s ease;*/
  border: none;
  background: #0d9fed;
  border-radius: 5px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: white;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.hidden-sm {
  display: block;
}

.visible-sm {
  display: none;
}

@media (max-width: 720px) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: block;
  }
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper-new {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.slider-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, .1));
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
  /* filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
  border: none;
  background: #fb8c00;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 10px 0px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  width: 150px;
}

.slide {
  height: 60vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));*/
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.2s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 10;
  background: url("./assets/slideArrow.svg") no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("./assets/slideArrow.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
  transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
}

.previousButton:hover {
  left: -5px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -5px;
}

.slider-content {
  text-align: center;
  will-change: contents;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* -webkit-transition-duration: 1s ease-in-out; */
}

.slider-content .inner {
  /*padding: 0 70px;*/
  /*box-sizing: border-box;*/
  position: absolute;
  /*width: 100%;*/
  top: 30%;
  left: 65%;
  text-align: center;
  margin-right: 10%;
  /*-webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);*/
  /*will-change: contents;*/
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%);
}

.slider-mobile p {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  /*line-height: 0.7;*/
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  /*margin: 20px auto 30px;*/
  max-width: 640px;
  font-weight: 500;
}

h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  /*margin: 20px auto 30px;*/
  max-width: 640px;
  font-weight: 500;
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 639px) {
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px;
  }
}

/* Slider Animations */

/*.slide img {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, -20px, 0px);
    transform: translate3d(0px, -20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide button {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide p {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide section * {
    transition: all 0.6s ease;
}

.slide.animateIn.previous img,
.slide.current img,
.slide.animateIn.next img,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
    opacity: 1;
    will-change: contents;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
    will-change: contents;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateOut img {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    will-change: contents;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    will-change: contents;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}*/

/* Slick */

.dummyDescription {
  text-align: justify;
  margin-top: 20px;
  margin-right: 25px;
}

.productDescription {
  text-align: justify;
  margin-top: 20px;
  margin-right: 25px;
}

.addressBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.addressBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.shopprofileBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.addressCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.shopprofileBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.shopprofileBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.autoshipBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 50px;
  text-decoration: none;
}

.autoshipBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.autoshipCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.suggested-slider .slick-dots {
  bottom: 100px !important;
  width: auto !important;
  left: 15% !important;
  right: 15%;
  background: none;
  cursor: pointer;
}

.suggested-slider-mobile .slick-dots {
  bottom: 100px !important;
  width: auto !important;
  left: 15% !important;
  right: 15%;
  background: none;
  cursor: pointer;
}

.suggested-slider .slick-dots li {
  margin: 0px !important;
}

.suggested-slider .slick-dots li button::before {
  color: grey !important;
  line-height: 30px !important;
}

.suggested-slider-mobile .slick-dots li {
  margin: 0px !important;
}

.suggested-slider-mobile .slick-dots li button::before {
  color: grey !important;
  line-height: 30px !important;
}

.slick-arrow {
  z-index: 99;
}

.slick-dots {
  width: 100px !important;
  bottom: 0px !important;
  left: calc(50% - 50px) !important;
  height: 45px !important;
  background-color: #606975;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button::before {
  color: white !important;
  line-height: 30px !important;
}

.gallary-container {
  height: 580px;
}

/** Toast Notification */

.s-alert-box {
  width: 300px;
  z-index: 999 !important;
}

.s-alert-box::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 8px 10px -4px rgba(0, 0, 0, 0.2);
  content: "";
  z-index: -1;
}

.s-alert-wrapper i {
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  display: table;
  font-size: 20px;
  line-height: 20px;
  margin-top: -11px;
  margin-left: 10px;
}

.s-alert-box-inner td,
.s-alert-box-inner th {
  padding: 8px;
}

.s-alert-box-inner strong,
.s-alert-box-inner p {
  margin-left: 20px !important;
}

.s-alert-close {
  top: 35% !important;
}

.s-alert-box:first-child {
  top: 10px;
}

.s-alert-box.s-alert-success {
  border: 1px solid rgba(67, 217, 163, 0.3) !important;
  background-color: #dcf8ee !important;
  color: #2bd396 !important;
}

.s-alert-success .s-alert-close::before,
.s-alert-success .s-alert-close::after {
  background: #2bd395 !important;
}

.s-alert-box.s-alert-warning {
  border: 1px solid rgba(255, 183, 79, 0.3) !important;
  background-color: #fff6e8 !important;
  color: #ffb140 !important;
}

.s-alert-warning .s-alert-close::before,
.s-alert-warning .s-alert-close::after {
  background: #ffb140 !important;
}

.s-alert-box.s-alert-info {
  border: 1px solid rgba(80, 198, 233, 0.3) !important;
  background-color: #e6f7fc !important;
  color: #39bfe6 !important;
}

.s-alert-info .s-alert-close::before,
.s-alert-info .s-alert-close::after {
  background-color: #39bfe6 !important;
}

.s-alert-box.s-alert-error {
  border: 1px solid rgba(255, 82, 82, 0.3) !important;
  background-color: #ffebeb !important;
  color: #ff5151 !important;
}

.s-alert-error .s-alert-close::before,
.s-alert-error .s-alert-close::after {
  background-color: #ff5252 !important;
}

/** Footer */

.hover-regis {
  color: white;
}

.footer-hover-quicklink:visited {
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.footer-hover-quicklink {
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.footer-hover-quicklink:hover {
  color: #0da9ef;
  cursor: pointer;
  background-position: center;
  /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.hover-quicklink:hover {
  color: #0da9ef !important;
  cursor: pointer;
  background-position: center;
  /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.cat-shop {
  padding-bottom: 16px;
}

.item-left {
  text-align: left;
}

.site-footer {
  padding-top: 30px;
}

.app-list {
  text-align: left;
  padding-bottom: 20px;
}

.app-list img {
  width: 60%;
}

.widget li:last-child .market-button {
  margin-right: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border: 0px;
  padding-top: 12px;
}

.input-group .form-control {
  padding-left: 45px;
}

.payments {
  background-image: url("./assets/paymnet_method_new.png");
  background-size: cover;
  background-position: -150px 0px;
  background-repeat: no-repeat;
  height: 60px;
  padding-left: 10%;
  width: 320px;
}

/** News */

.news {
  padding-top: 25px;
}

.img-news {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

.gallery-item {
  padding-bottom: 30px;
}

.x {
  border: 0px;
}

/** Burger Menu */

/* Hides everything pushed outside of it */

.site-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.push-wrap,
.pwblock,
.offcanvas-menu {
  -webkit-transition: all 400ms ease 0ms;
  -moz-transition: all 400ms ease 0ms;
  -o-transition: all 400ms ease 0ms;
  transition: all 400ms ease 0ms;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.show-nav .push-wrap,
.show-nav .pwblock,
.show-nav .offcanvas-menu {
  -webkit-transform: translate(250px, 0);
  -moz-transform: translate(250px, 0);
  -ms-transform: translate(250px, 0);
  -o-transform: translate(250px, 0);
  transform: translate(250px, 0);
  -webkit-transform: translate3d(250px, 0, 0);
  -moz-transform: translate3d(250px, 0, 0);
  -ms-transform: translate3d(250px, 0, 0);
  -o-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0);
}

.show-nav #sidebar-nav {
  left: -250px;
}

/* Positions the nav fixed below the push wrapper */

.account-link {
  margin-bottom: 0px !important;
  display: block;
  padding: 0px 10px;
  transition: background-color 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #313b47;
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.account-link .rank {
  font-size: 9px;
}

#sidebar-nav {
  width: 250px;
  height: 100%;
  position: fixed;
  padding-left: 0;
  left: -250px;
  top: 0;
  background: #374250;
  -webkit-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  cursor: pointer;
}

#sidebar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidebar-nav ul li > span {
  display: block;
  padding: 15px 20px;
  transition: color 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
}

#sidebar-nav ul li.active > span {
  color: #0da9ef;
}

.lock-scroll-bar {
  overflow-y: hidden !important;
}

#sidebar-nav ul li.has-children .sub-menu-toggle {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  height: calc(100% - 1px);
  transition: background-color 0.3s;
  color: transparent;
  cursor: pointer;
  z-index: 1;
  border-left: 0px;
}

#sidebar-nav ul li.has-children .sub-menu-toggle:hover {
  background-color: transparent;
}

#sidebar-nav ul li.has-children .sub-menu-toggle::before {
  display: block;
  position: absolute;
  top: 50%;
  right: 25px;
  width: 0;
  height: 0;
  margin-top: -5px;
  border-top: 0.35em solid transparent;
  border-bottom: 0.35em solid transparent;
  border-left: 0.4em solid;
  content: "";
}

.offcanvas-menu ul li.back-btn > span::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 8px;
  border-top: 0.32em solid transparent;
  border-right: 0.37em solid;
  border-bottom: 0.32em solid transparent;
  content: "";
  opacity: 0.75;
}

#sidebar-nav .offcanvas-submenu {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: auto;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

#sidebar-nav .offcanvas-submenu.in-view {
  transform: translate3d(0, 0, 0);
}

.offcanvas-menu li {
  display: block;
}

.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.site-menu > ul > li.has-megamenu {
  color: #0da9ef !important;
}

.logo-gradient {
  background-image: linear-gradient(
    -90deg,
    #5468ad 12%,
    #448ed7 55%,
    #30acea 100%
  );
}

.logout {
  position: relative;
  width: 100%;
  border-top: 1px solid #e1e7ec;
  height: 40px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
}

.logout-sm {
  position: relative;
  width: 100%;
  /*border-top: 1px solid #e1e7ec;*/
  height: 25px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
}

.logout-sm:hover {
  position: relative;
  width: 100%;
  /*border-top: 1px solid #e1e7ec;*/
  height: 25px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
  color: #0da9ef;
}

.logout:hover {
  position: relative;
  width: 100%;
  border-top: 1px solid #e1e7ec;
  height: 40px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  color: #0da9ef;
}

.logout-label {
  top: 8px;
  position: relative;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.stuff-transform {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.logout.active {
  border-color: #0da9ef;
  background-color: #0da9ef;
  color: #fff;
  pointer-events: none;
}

/** All about media query */

@media (min-width: 768px) {
  .modal-dialog {
    width: 500px !important;
  }
  .app-list a {
    min-width: 158px;
  }
  .padding-title-look-forward {
    padding-top: 24px !important;
  }
}

.container {
  width: 100% !important;
}

@media (min-width: 1367px) {
}

@media (max-width: 1280px) {
  .promotion {
    text-align: center;
  }
  .promotion > img {
    object-fit: contain;
  }
  .slider-button {
    padding: 10px 25px;
  }
}

@media (max-width: 1200px) {
  .toolbar .cart > .subtotal {
    display: inline !important;
  }
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
}

@media screen and (max-width: 2560px) {
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
}

@media only screen and (min-width: 1024px) {
  .smaller-text {
    font-size: 13px;
    height: 33px;
    max-width: 84px;
    padding: 5px 10px;
  }
  .global-connected-button-left {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1025px) {
  .smaller-text {
    font-size: 13px;
    height: 33px;
    max-width: 120px;
    padding: 5px 20px;
  }
}

@media screen and (max-width: 1024px) {
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
  .slick-list {
    position: initial !important;
  }
  .topbar.route-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
  header .site-menu {
    display: block !important;
  }
  .status-button {
    padding: 10px;
  }
  .success-badge {
    margin-right: 0px;
  }
  .gallary-container {
    height: auto;
  }
  .rank-pin {
    padding-bottom: 0%;
  }
  .rank-badges .slick-slide.slick-active .rank-pin > p {
    border-color: rgb(13, 169, 239);
    color: rgb(13, 169, 239);
  }
  .gallary-slides.success-page {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
  }
  .rank-badges .slick-dots {
    top: 88px;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .rank-badges .slick-dots li button::before {
    color: black !important;
  }
  .rank-badges .slick-dots li button {
    background: none;
  }
  .rank-badges .slick-dots li button:hover {
    cursor: pointer;
  }
  .rank-badges .slick-prev {
    left: 0px;
  }
  .rank-badges .slick-prev:before,
  .rank-badges .slick-next:before {
    color: rgb(13, 169, 239);
    font-size: 40px;
  }
  .rank-badges .slick-next {
    right: 10px;
  }
  .col-11.success-fix-width {
    flex: 0 0 90%;
    max-width: 90%;
  }
  td.product-detail {
    width: 50% !important;
    padding: 0px;
  }
  td.product-button {
    width: 50% !important;
    padding: 0px;
  }
}

@media screen and (min-width: 1680px) {
  .slick-slide {
    height: 600px !important;
  }
}

@media screen and (min-width: 2100px) {
  .QtyDetail input {
    position: relative;
    bottom: 0px;
  }
  .detail-image {
    margin-left: 21px;
  }
}

@media screen and (min-width: 2300px) {
  .QtyDetail input {
    position: relative;
    bottom: 0px;
  }
}

@media (max-width: 992px) {
  .hipster-dude {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .app-list {
    display: inline;
  }
  .page-container .site-menu {
    display: block !important;
    position: unset;
  }
  .gallary-cells {
    align-items: center;
    justify-content: flex-end;
  }
  .gallary-cell-bottom {
    justify-content: flex-start !important;
  }
  .gallary-cell-bottom-image {
    margin-top: 20px;
  }
  .s-alert-box {
    width: 100%;
    max-width: unset !important;
    left: 0 !important;
    right: 0 !important;
  }
  /*.widget.widget-light-skin {
         text-align: center!important 
    }*/
  .app-list img {
    width: 60% !important;
  }
  main .site-menu {
    display: block !important;
    height: 40px;
  }
  main .site-menu > ul > li > a {
    display: block;
  }
  .qs-row {
    margin-right: 70px;
  }
  .col-11.success-fix-width {
    flex: 0 0 95%;
    max-width: 85%;
  }
  .sort-by-ov {
    width: 52%;
  }
  .product-card .product-thumb {
    display: block;
    width: 100%;
    padding: 24px;
  }
  .slick-initialized .slick-slide .product-card .product-thumb {
    height: 200px;
  }
  .form-order-details-center {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media screen and (min-width: 421px) and (max-width: 640px) {
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px !important;
  }
}

@media screen and (min-width: 640px) {
  .new-left-name {
    font-size: 12px;
  }
  .toolbar {
    right: 15px;
  }
  .detail-btn {
    margin-bottom: 15px;
    white-space: nowrap;
  }
  .site-logo > img {
    margin-left: 3px;
    margin-top: -4px;
    width: 120px !important;
    max-width: none;
  }
}

@media screen and (min-width: 500px) {
  .gene-search {
    float: left;
    width: 50%;
  }
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px;
  }
}

@media screen and (max-width: 480px) {
  .new-left-name {
    font-size: 12px;
  }
  .productDescription {
    text-align: justify;
    margin-top: 20px;
    margin-right: 25px;
    margin-left: 21px;
  }
  .site-logo > img {
    margin-left: 3px;
    margin-top: -5px;
    max-width: none;
  }
  /*.site-logo {
        width: 135px;
    }*/
  .report-btn {
    padding: 6px;
    padding-right: 10px;
    text-align: center;
  }
  .shopping-cart-footer > .btn-success {
    margin-right: 0;
  }
  .payments {
    width: 320px;
    background-position-x: -122px;
  }
  .lang-currency-switcher-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }
  .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
    position: fixed;
    top: 225%;
    left: 34%;
    margin-top: -50px;
    margin-left: -100px;
    width: 320px;
  }
  .qs-add-to-cart {
    font-size: 10px;
  }
  .qs-out-of-stock {
    font-size: 10px;
  }
  .qs-quicknav {
    width: 25px;
  }
  .qs-row {
    margin-right: 30px;
  }
  .small-no-pad {
    padding: 0px;
    padding-right: 5px;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-row.new-line {
    top: -20px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
}

@media screen and (max-width: 414px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 45px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 45px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .button-product-detail {
    margin-left: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .sliderImgBios {
    width: auto;
    height: 150px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 55px;
  }
  .sliderImgUni {
    width: auto;
    height: 155px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .profile-pic-side {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    vertical-align: middle;
    padding: 1px;
  }
  .success-position {
    position: relative;
    right: 25px;
  }
  .sliderImg {
    position: relative;
    height: 270px;
  }
  .sort-by-ov {
    width: 52%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 140px;
  }
}

@media screen and (max-width: 375px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 65px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 65px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .button-product-detail {
    margin-left: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .sliderImgBios {
    width: auto;
    height: 100px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 55px;
  }
  .sliderImgUni {
    width: auto;
    height: 135px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .profile-pic-side {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    vertical-align: middle;
    padding: 1px;
  }
  .success-position {
    position: relative;
    right: 25px;
  }
  .sliderImg {
    width: auto;
    height: 270px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  /*.slider-button {
        border: 1px solid;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-left: 100px;
        margin-right: 100px;
        position: relative;
        border-color: #4EAD75;
        top: 20px
    }*/
  .site-logo > img {
    margin-left: -14px;
    margin-top: -5px;
    width: 100px !important;
    max-width: none;
  }
  .sort-by-ov {
    width: 47%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 33px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 120px;
  }
}

@media screen and (max-width: 360px) {
  .modal-content {
    min-width: 0px !important;
  }
  .offcanvas-toggle {
    width: 40px;
  }
  .site-logo > svg {
    margin-left: 10px;
  }
  .sort-by-ov {
    width: 45%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
}

@media screen and (max-width: 320px) {
  /* .offcanvas-toggle {
        width: 40px;
    } */
  .topbar .topbar-column:first-child > a:not(.social-button),
  .topbar .topbar-column:first-child > span,
  .topbar .topbar-column:first-child > p {
    margin-right: 15px;
  }
  .topbar .topbar-column:first-child > a:last-child {
    margin-right: 0;
  }
  .sort-by-ov {
    width: 37%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 20px;
  }
  .genealogy-page-wrapper button.hidden-md-up.btn {
    padding: 0 13px;
  }
  .info-btn {
    padding: 8px 13px;
  }
}

@media screen and (max-width: 346px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .refreshBtn {
    margin-bottom: 10px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .button-product-detail {
    margin-left: 20px;
  }
}

@media screen and (max-width: 320px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .button-product-detail {
    margin-left: 10px;
  }
  .sliderImgUni {
    width: auto;
    height: 106px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .topbar .topbar-column {
    width: auto;
  }
  /*.topbar .topbar-column-left {
        width: 60%;
    }*/
  /*.topbar .topbar-language-column {
        width: 40%;
        float:right;
    }*/
  .sliderImg {
    position: relative;
    height: 250px;
  }
  .drop-login {
    right: 100px;
    width: 250px;
  }
  .drop-login a {
    font-size: 14px;
  }
  .topbar.route-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
  .lang-currency-switcher-wrap {
    margin-left: 8px;
  }
  .lang-currency-switcher-wrap .dropdown-menu {
    width: 200px;
    left: -47px;
  }
  .language.lang-currency-switcher-wrap .dropdown-menu {
    width: 210px;
  }
  .slick-list {
    top: 120px;
  }
  .img-container {
    top: -50px;
  }
  .scroll-to-top-btn {
    width: 44px;
    height: 44px;
    right: 32px !important;
  }
  .scroll-to-top-btn.visible {
    bottom: 40px;
    opacity: 0.7;
  }
  .page-title {
    padding: 15px 10px;
  }
  .menu-container,
  #menu-container {
    width: 250px;
  }
  .enroll-form .quick-shop {
    margin-top: 80px;
  }
  .widget-categories > ul > li {
    margin-left: -15px;
  }
  .quick-shop .widget-categories > ul > li > ul {
    margin-left: 10px;
  }
  .home-row-buttons .global-connected-button-left,
  .home-row-buttons .global-connected-button-right,
  .home-row-buttons .global-connected-button-middle,
  .home-row-buttons .global-connected-button-active {
    padding: 2px 10px;
  }
  .app-list img {
    width: 80%;
  }
  .site-logo > img {
    margin-left: -14px;
    margin-top: -4px;
    max-width: none;

    /*width: 10%;*/
  }
  .toolbar {
    right: 5px;
  }
  .payments {
    width: 100% !important;
    background-position-x: -155px;
  }
  .app-list {
    text-align: left;
    display: block;
    padding-bottom: 10px;
  }
  .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
    position: fixed;
    top: 225%;
    left: 34%;
    margin-top: -50px;
    margin-left: -100px;
    width: 300px;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .checkout h5 {
    font-size: 16px;
  }
  .qtyplusminusbtn {
    padding: 0px 16px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 100px;
  }
}

@media screen and (max-width: 550px) {
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .genealogy-table {
    width: 300%;
  }
  .genealogy-table > thead > tr > th {
    padding: 0px;
  }
  .genealogy-table > tbody > tr > td {
    padding: 0px;
  }
}

.promotion {
  min-height: 480px;
}

@media screen and (max-width: 550px) {
  .promotion {
    min-height: 100px;
  }
}

.edit-button {
  color: #0c9fed;
  background-color: white;
}

.edit-button-hover {
  color: #097cb7;
  background-color: white;
}

/* Shop Profile */

.shopprofile-tab .padding-top-1x {
  padding-top: 20px !important;
}

/* referral link */

.referral-link-tab .padding-top-1x {
  padding-top: 125px !important;
}

.genealogy-page-wrapper .btn-group {
  float: right;
  margin-right: 0 !important;
  margin-top: 0;
  margin-left: 7px;
}

.genealogy-page-wrapper .btn:hover,
.genealogy-page-wrapper .btn:focus,
.genealogy-page-wrapper .btn:active {
  border-color: transparent;
  box-shadow: none;
}

.genealogy-page-wrapper .btn-info {
  background-color: #2bb8f4;
}

.genealogy-page-wrapper .btn-secondary:active,
.genealogy-page-wrapper .btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none;
  color: #606975;
}

.genealogy-page-wrapper .btn-info:active,
.genealogy-page-wrapper .btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
  background-color: #2bb8f4;
  border-color: transparent;
  box-shadow: none;
}

.dropdown-menu::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#3bb3e8),
    to(#28abe4)
  );
  background-image: -webkit-linear-gradient(bottom, #3bb3e8 0%, #28abe4 100%);
  background-image: linear-gradient(to top, #3bb3e8 0%, #28abe4 100%);
}

@media (max-width: 767px) {
  .referral-link-tab .padding-top-1x {
    padding-top: 15px !important;
  }
}

@media (max-width: 725px) {
  .cart-img {
    /*display: none !important;*/
    /* width:40%; */
  }
  .mob-cart-img {
    display: block;
  }
  .mob-cart-img img {
    width: auto;
    max-width: 110px;
    height: auto;
    max-height: 90px;
    margin: 8px auto;
  }
  .shopping-cart .product-button label {
    margin: 0px;
    font-family: "Maven Pro", sans-serif;
  }
  /* .cart-img img{
        width: 100%;
        margin-left: 27%;
    } */
}

@media (min-width: 768px) and (max-width: 1021px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 20px;
  }
}

@media (min-width: 1280px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
  .gene-search {
    max-width: 180px !important;
    float: right;
  }
  .gene-search-button {
    margin-right: -170px;
    float: right;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 10px;
  }
  .gene-search {
    max-width: 180px !important;
    float: right;
  }
  .gene-search-button {
    margin-right: -170px;
    float: right;
  }
}

/* enroll */

.enrollment-page .dropdown-toggle::after {
  margin-top: 18px !important;
  float: right !important;
}

.enrollment-page .dropdown-menu {
  min-width: 15rem;
}

.enrollment-page .disabled.dropdown-item:hover {
  color: #000000 !important;
}

@media (max-width: 897px) and (min-width: 321px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    font-size: 11px;
    padding: 0px 5px;
  }
}

@media (max-width: 330px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    padding: 0px 5px;
  }
}

@media (max-width: 860px) and (min-width: 767px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    font-size: 9px;
  }
}

.enroll-qs-row .container {
  width: auto;
}

.enroll-qs-row {
  margin-right: 30px;
  padding-bottom: 5px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .enroll-qs-row {
    margin-right: 70px;
  }
}

@media screen and (max-width: 480px) {
  .enroll-qs-row {
    margin-right: 30px;
  }
}

.enroll-profile-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.view-ba.profileCard {
  width: auto;
}

@media (max-width: 330px) {
  #btn-leave-enroll-cancel,
  #btn-leave-enroll-ok {
    font-size: 10px;
  }
}

.enroll-summary {
  line-height: 1.8;
}

.enroll-summary td {
  vertical-align: top;
  font-size: inherit;
  padding: 0px;
}

.enroll-summary .info-field-title {
  width: 150px;
}

.enroll-summary .contact-field-title {
  width: 85px;
}

@media screen and (max-width: 767px) {
  .enroll-summary .info-field-title,
  .enroll-summary .contact-field-title,
  .enroll-summary .summary-field-title {
    width: 130px;
  }
  .maintainLabel {
    font-size: 10vw;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

body.show-nav ::-webkit-scrollbar {
  display: none;
}

.youtube-foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*transform: translate(-50%, -50%);*/
  background: rgba(0, 0, 0, 0.001) url(./assets/screen.png);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.button-center {
  display: flex;
  align-items: center;
}

.filter {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #999999;
  text-decoration: none;
  -webkit-appearance: button;
  background: none;
  border: 0px;
}

.filter-on {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #25b0f3;
}

.filter-off {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #999999;
}

.maintainanceBg {
  background-image: url("./assets/coming_soon.jpg");
}

.mobileBannerContent {
  text-align: right;
  margin-bottom: 8%;
  margin-right: 10%;
}

.mobileFilter {
  opacity: 0;
  max-height: 0;
}

.show {
  opacity: 1;
  max-height: 3000px;
}

/*Sathya UI - II*/

.bottom-50 .slick-dots {
  bottom: 50px !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.no-padding-imp {
  padding: 0px !important;
}

.no-margin-imp {
  margin: 0px !important;
}

.list-pills:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.spinner-align {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
}

.display-none {
  display: none !important;
}

/** Youtube Loader CSS **/

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-css {
  left: calc(50% - 37px);
  top: calc(48% - 37px);
  position: absolute;
}

.lds-spinner {
  position: relative;
}

.lds-spinner div {
  left: 96px;
  top: 42px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #ffffff;
  width: 8px;
  height: 36px;
  border-radius: 20%;
  -webkit-transform-origin: 4px 58px;
  transform-origin: 4px 58px;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.lds-spinner {
  width: 75px !important;
  height: 75px !important;
  -webkit-transform: translate(-37.5px, -37.5px) scale(0.375)
    translate(37.5px, 37.5px);
  transform: translate(-37.5px, -37.5px) scale(0.375) translate(37.5px, 37.5px);
}

/** Youtube Loader CSS Ends**/

.youtube-logo {
  bottom: 17px;
  right: 17px;
  position: absolute;
  width: 108px;
}

/* Show/Hide Password*/

.showPass {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  font-size: 1.2em;
}

/** Firefox specific CSS **/

@-moz-document url-prefix() {
  .enrollment-page .dropdown-toggle::after {
    margin-top: -23px !important;
    float: right !important;
  }
  .floatLeft {
    float: left;
  }
}

.currency {
  padding: 4px 0 4px 12px;
  border-left: 1px solid #e1e7ec;
  font-size: 14px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-control.custom-checkbox {
  display: inline-block;
}

.loaderLogin .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.tbTrip > table > tbody > tr > th {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

th.thTitle {
  background-color: #374250 !important;
}

th.thMonth {
  background-color: #838a92 !important;
}

.tbTrip {
  /* Rectangle: */
  border: 0.5px solid #e1e7ec;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  perspective: 1px;
  border-radius: 5px;
  border-collapse: separate;
}

.download-btn {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 5px;
  line-height: 5px;
  color: #ffffff;
  width: 100px;
  font-size: 16px;
  font-weight: 100;
  padding: 15px;
  background-color: #0da9ef;
  border: solid #0da9ef 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.downloadRow {
  border: 0.2px solid #e1e7ec;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1),
    0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-perspective: 1px;
  perspective: 1px;
  border-radius: 5px;
  border-collapse: separate;
}

.seminar-btn {
  /* Rectangle 4: */
  background: #37a0e2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 94.5px;
}

.tbTrip > table > tbody > tr > td {
  font-size: 18px;
  font-weight: bold;
  color: #606975;
}

.th-commission-dropdown {
  min-width: 155px;
  border-radius: 5px;
}

.th-shipping-dropdown {
  min-width: 160px;
  border-radius: 5px;
}

.th-commission-dropdown .icon-circle-check,
.th-shipping-dropdown .icon-circle-check {
  margin-bottom: 5px;
  padding-right: 3px;
}

/************ SWITCH ***********/

.switchButton {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* top: 10px; */
}

.switchButton input {
  display: none;
}

.switch-confirm-container td {
  padding: 0px;
}

@media (max-width: 414px) {
  .switch-confirm-container {
    text-align: left;
    padding-left: 0px;
  }
  .switch-confirm-container .custom-control {
    padding-left: 0px !important;
  }
}

.sliderButton {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderButton:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderButton {
  background-color: #2196f3;
}

input:focus + .sliderButton {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderButton:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.sliderButton.round {
  border-radius: 34px;
}

.sliderButton.round:before {
  border-radius: 50%;
}

/* input:checked+.sliderButton:after {
    font-family: feather;
    font-size: 17px;
    content: "\e116";
    padding-left: initial;
    top: 2px;
    position: absolute;
    left: 9px;
    color: white;
} */

.dropdown-button .btn-primary:hover {
  background-color: #0da9ef;
  border-color: #0da9ef;
}

.dropdown-button .btn-outline-secondary:hover {
  background-color: white;
  border-color: #e1e7ec;
}

p.limit_age {
  padding-top: 40px;
  padding-left: 8px;
}
.ann-box {
  border: 1px solid #dee2e6;
  border-right: 0;
  padding: 51px 0;
}
@media (max-width: 576px) {
  p.limit_age {
    padding-top: 0;
  }
  .ann-box {
    border: 1px solid #dee2e6;
    border-bottom: 0;
    padding: 25px 0;
  }
}

.cart-button {
  align-items: center;
  display: flex;
}

.thMonth {
  background-color: #838a92 !important;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:focus {
  box-shadow: none;
}
.btn-danger:not(:disabled):not(.disabled):active {
  border-color: #bd2230;
}
.checkout-page.page-title {
  margin-bottom: 0;
}
.checkout-page.page-title .column:first-child {
  padding-right: 0;
}
#frmPaymentShopping .form-control-label {
  padding-left: 0;
}
#frmPaymentShopping .form-control {
  font-size: 13px;
}
.expiry-date .dropdown-menu {
  min-width: auto;
  text-align: center;
  width: 87%;
}
.expiry-date .btn-outline-primary {
  width: 100%;
  text-align: center;
  font-weight: normal;
}
.expiry-date .btn-outline-primary {
  color: #606975;
  border: 1px solid #dbe2e8;
}
.expiry-date .btn-outline-primary:not(:disabled):not(.disabled):active,
.expiry-date .btn-outline-primary:hover {
  color: #606975;
  background-color: rgb(255, 255, 255);
  border-color: #dbe2e8;
}
.expiry-date .btn-outline-primary:focus {
  box-shadow: none;
}
.expiry-date .pills {
  margin-top: 0;
}
.expiry-date .month .pills {
  margin-left: 0;
}
.ID-css .add-to-cart {
  width: 167px;
  height: auto;
  border-radius: 23px;
}
.height40 {
  height: 40px;
}
.ID-css .height40 {
  height: auto;
}
.hundred-percent-width {
  width: 100%;
}
.ID-css .qtyInput {
  height: 46px;
}
.ID-css .out-of-stock {
  height: 46px;
  border-radius: 23px;
}

.widget ul a {
  margin-bottom: 12px;
  text-decoration: none !important;
}
.item-left a {
  text-decoration: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.7rem !important;
}

.enroll-summary td {
  vertical-align: top !important;
  font-size: inherit;
  padding: 0px !important;
}

.enroll-summary tr td + td {
  padding: 0px 0px 0px 3px !important;
}

.page-title {
  margin-bottom: 25px !important;
}

.address-capital {
  text-transform: capitalize !important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* text-align: right; */
  float: right !important;
  margin-top: 19px !important;
}

.show {
  opacity: 1;
  max-height: 3000px;
  /* width: 0% !important; */
}

.jp-form-group.form-group.mb-10 {
  margin-bottom: 10px !important;
}

.jp-form-group.form-group.mb-20 {
  margin-bottom: 20px !important;
}

.shipping-contact-table {
  table-layout: fixed;
  word-wrap: break-word;
}

/* new input animation */
.form-group.levitate {
  height: 46px;
  border: 0;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.form-group.levitate label {
  position: relative;
  top: -3.5rem;
  transition: all 0.05s ease-in-out;
  cursor: text;
  color: rgba(185, 185, 185);
  font-size: 13px;
  font-weight: normal;
  border: 3px solid rgba(255, 255, 255, 0);
  margin: 0 auto;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

/* .form-control.levitate {
}

.form-control.levitate:focus {
} */

.form-control.levitate:focus + label {
  position: relative;
  top: -5.5rem;
  margin: 0 auto;
  padding: 0;
  background: white;
  line-height: 5px;
  font-size: 12px;
  color: #0da9ef;
  transition-property: color, top, left;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}

.form-control.levitate:disabled {
  background: white;
}

/* filled, but invalid */
/* .form-control.levitate:not(:placeholder-shown) {} */

/* valid */
/* .form-control.levitate:valid {} */

.form-control.levitate:valid:not(:focus) + label {
  position: relative;
  top: -5.5rem;
  padding: 0;
  background: white;
  line-height: 5px;
  font-size: 12px;
  color: #aaaaaa;
  transition: color 0.1s ease-in-out;
}

.form-control.levitate:disabled:not(:focus) + label {
  position: relative;
  top: -5.5rem;
  padding: 0;
  background: white;
  line-height: 5px;
  font-size: 12px;
  color: #aaaaaa;
}

/* .form-control.levitate::placeholder {
    color: #CFCFCF;
    font-size: 13px;
} */

.form-control.levitate:-webkit-autofill,
.form-control.levitate:-webkit-autofill:hover,
.form-control.levitate:-webkit-autofill:focus,
.form-control.levitate:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* - End - new input animation */

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .hide-only-ipad img {
    position: absolute;
    height: 101% !important;
  }
}

.form-order-details-center {
  width: 50%;
  margin: 0 auto;
}

.form-order-details-jp {
  width: 100% !important;
  margin: 0 auto;
}

.country-flag-icon {
  width: 20px !important;
  margin-right: 10px;
}

.country-flag-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.login-form-m {
  margin-bottom: 0px !important;
}

.padding-log-menu {
  padding-top: 10px !important;
}

.getinfomation-form .input-group {
  width: calc(100% - 88px);
  float: left;
}
.getinfomation-form .btn {
  margin: 0;
  float: right;
}
.getinfomation-form .btn > i {
  margin-top: -6px;
  font-size: 1.5em;
}
.getinfomation-form .form-text {
  padding-top: 12px;
}
.whitefade {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 999;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.topsclicked {
  display: block;
  position: fixed;
  right: 16px;
  bottom: -88px;
  width: 44px;
  height: 44px;
  transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s,
    background-color 0.3s, border-color 0.3s;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 18px;
  line-height: 39px;
  opacity: 0;
  z-index: 2000;
  text-align: center;
  text-decoration: none;
}

.topsclicked:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.topsclicked:focus,
.topsclicked:active {
  color: #fff;
}

.topsclicked.visible {
  bottom: 14px;
  opacity: 1;
}

@media (max-width: 768px) {
  .topsclicked {
    bottom: -72px;
    width: 36px;
    height: 36px;
    line-height: 31px;
  }
}

.showbtn {
  display: inline-block;
  margin-top: 5px;
  margin-right: 18px;
  margin-bottom: 5px;
  transition: color 0.3s;
  color: #606975;
  font-size: 13px;
  text-decoration: none;
  vertical-align: middle;
}

.showbtn.shape-circle,
.showbtn.shape-rounded,
.showbtn.shape-square {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border: 1px solid #e1e7ec;
  line-height: 35px;
  text-align: center;
}

.showbtn.shape-circle {
  border-radius: 50%;
}

.showbtn.shape-rounded {
  border-radius: 5px;
}

.showbtn:focus {
  text-decoration: none;
}

.text-center .showbtn {
  margin-right: 9px;
  margin-left: 9px;
}

.text-center .showbtn.shape-circle,
.text-center .showbtn.shape-rounded,
.text-center .showbtn.shape-square {
  margin-right: 4px;
  margin-left: 4px;
}

.text-right .showbtn {
  margin-right: 0;
  margin-left: 18px;
}

.text-right .showbtn.shape-circle,
.text-right .showbtn.shape-rounded,
.text-right .showbtn.shape-square {
  margin-right: 0;
  margin-left: 8px;
}

[class*=" showing-"]:before,
[class^="showing-"]:before,
[data-icon]:before {
  font-family: socicon !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.showing-fb:before {
  content: "\e041";
}
.showing-yt:before {
  content: "\e051";
}
.showing-ig:before {
  content: "\e057";
}

.topicons {
  display: none;
}

/*** Customize Checkbox ***/
.container-checkbox {
  display: table;
  margin: auto;
}

.checkbox-label {
  display: block;
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 2.25rem;
  padding-right: 0.5rem;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: relative;
  vertical-align: top;
  opacity: 0;
  cursor: default;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1.1rem;
  left: 0;
  height: 14px;
  width: 14px;
  margin-top: -0.75rem;
  background-color: #dee2e6;
  border-radius: 0.25rem;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #dee2e6;
}

/* When the checkbox is checked, add a blue background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #0da9ef;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: inline-block;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  opacity: 1;
  transition: 0.05s all ease-in;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  left: 6px;
  top: 8px;
  width: 1px;
  height: 1px;
  opacity: 0;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: 0.05s all ease-in;
}
/*** End - Customized Checkbox ***/

.no-xscroll {
  overflow-x: hidden;
}

.no-pointer-events {
  pointer-events: none !important;
}

.cursor-none {
  cursor: none !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-progress {
  cursor: progress !important;
}

.cursor-copy {
  cursor: copy !important;
}

.cursor-move {
  cursor: move !important;
}

.transition-100ms {
  transition: all 0.1s;
}

.transition-250ms {
  transition: all 0.25s;
}

.transition-500ms {
  transition: all 0.5s;
}

.transition-1s {
  transition: all 1s;
}

.transition-2s {
  transition: all 2s;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.bd,
.border-dashed {
  border: 1px dashed black;
}

.custom-control .custom-control-input:disabled ~ .custom-control-label {
  color: #333333 !important;
  cursor: default !important;
}

.process-advance-hydra.card {
  min-height: 500px;
}

.process-advance-hydra .spinner {
  margin: 6rem auto 6rem auto;
}

.process-advance-hydra .dot-typing {
  color: black !important;
  background-color: black !important;
}

.box-devtools.card {
  position: absolute;
  z-index: 999;
  cursor: default;
  box-shadow: rgba(200, 200, 200, 0.15) 1.25px 1.25px 4px;
}

.box-devtools .btn-close {
  padding: 0.2rem;
  cursor: pointer;
}

.box-devtools .btn-close:hover {
  color: red;
}

.box-devtools-hydra .ant-form-item-label {
  font-weight: bold;
  padding-bottom: 0;
}

.box-devtools-hydra .ant-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.box-devtools-hydra .ant-form-item {
  margin-bottom: 0px;
}

.shrink-w-0 > * {
  width: 0px !important;
  max-width: 0px !important;
}

.shrink-w-1 > * {
  width: auto !important;
  max-width: auto !important;
}

.shrink-h-0 > * {
  height: 0px !important;
  max-height: 0px !important;
}

.spinner-float {
  display: block;
  position: absolute;
}
.error-message .ant-alert {
  padding: 0;
  font-size: 13px;
}

.error-message .ant-alert-error {
  color: #ff5252;
  border: 1px solid rgba(255, 82, 82, 0.12);
  background-color: rgba(255, 82, 82, 0.12);
  border-radius: 7px;
}

.error-message .ant-alert-error .ant-alert-description > ul {
  margin: 0;
  padding: 4px;
  border: 4px solid white;
  border-radius: 7px;
  text-align: center;
  list-style: none;
}

.error-message .ant-alert-with-description.ant-alert-no-icon {
  padding: 0;
}

.error-message .ant-alert-message {
  display: none;
}

/*** visa mc ***/
.we_accept {
  margin-top: 11px;
}

.we_accept .row {
  justify-content: center;
  align-items: center;
}
.we_accept .row .text {
  margin-right: 6px;
  font-size: 12px;
  text-transform: uppercase;
}
.we_accept .row .image img {
  width: 90px;
}

/*  Shut Down Page */
.shutdown-page {
  width: 100%;
  height: 100%;
  background: black;
  padding: 3%;
}
.shutdown-page .shutdown-page-list {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: white;
  background-image: url(./assets/IDN_Announcement_Close_BG_desktop.png);
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 100px;
  margin-bottom: 60px;
}
.shutdown-page .shutdown-page-list:nth-of-type(2) {
  margin-bottom: 0px;
}
.shutdown-page .shutdown-page-list .logo img {
  width: 220px;
}
.shutdown-page .shutdown-page-list .block-text {
  margin-top: 85px;
  padding: 0 25%;
}
.shutdown-page .shutdown-page-list .block-text .title {
  font-size: 45px;
  font-weight: bold;
}
.shutdown-page .shutdown-page-list .block-text .content {
  font-size: 20px;
}
.shutdown-page .shutdown-page-list .block-text .content .text-bottom {
  margin-top: 20px;
}
.shutdown-page .shutdown-page-list .button {
  margin-top: 80px;
  display: inline-block;
}
.shutdown-page .shutdown-page-list .button a {
  font-size: 24px;
  font-weight: 500;
}
.shutdown-page .shutdown-page-list .button a:after {
  content: "";
  display: inline-block;
  background: white;
  height: 1px;
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .shutdown-page {
    padding: 5%;
  }
  .shutdown-page .shutdown-page-list {
    background-image: url(./assets/IDN_Announcement_Close_BG_Mobile.png);
    margin-bottom: 5%;
  }
  .shutdown-page .shutdown-page-list .logo img {
    width: 165px;
  }
  .shutdown-page .shutdown-page-list .block-text .title {
    font-size: 35px;
  }
  .shutdown-page .shutdown-page-list .block-text .content {
    font-size: 16px;
  }
  .shutdown-page .shutdown-page-list .button a {
    font-size: 20px;
  }
  .shutdown-page .shutdown-page-list .block-text {
    padding: 0 13%;
  }
  .shutdown-page .shutdown-page-list .button {
    margin-top: 130px;
  }
  .shutdown-page .shutdown-page-list {
    padding-bottom: 130px;
  }
}

.icon-profile-tab {
  display: inline-flex;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-top: 8px;
  margin-right: 15px;
}

.logout > * {
  transition: none !important;
}

.logout.active > * {
  transition: none !important;
}

.logout:hover .icon-profile-tab svg path {
  fill: #0da9ef;
}

.logout.active .icon-profile-tab svg path {
  fill: white;
}

.modal-searchzip .ant-layout {
  background-color: white;
}

.modal-searchzip .searchzip-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal-searchzip .ant-modal-content {
  border-radius: 7px;
  min-height: 187px;
}

.modal-searchzip .ant-input-search input {
  width: 100%;
  font-family: Prompt, sans-serif !important;
  border: 1px solid #e3e8ed;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  color: #000;
  font-size: 16px;
  transition: all 0.25s ease-in-out;
}

.modal-searchzip .ant-input-group > .ant-input:first-child {
  border-radius: 7px;
}

.modal-searchzip .ant-input-search .ant-input-group-addon {
  display: none;
}

.modal-searchzip .icon-searchzip {
  width: 48px;
  height: 48px;
  top: 1px;
  right: 4px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  font-size: 18px;
  border: transparent 1px solid;
  border-radius: 7px;
  z-index: 10;
}

.modal-searchzip .icon-searchzip {
  cursor: default;
}

.modal-searchzip .searchzip-error {
  color: red;
}

.modal-searchzip .ant-input-search .ant-input-search-button {
  background-color: #606975;
}

.modal-searchzip .ant-modal-footer {
  display: none;
}

.modal-searchzip .searchzip-infinite-found-items {
  color: #606975;
  text-align: center;
  padding-bottom: 10px;
}

.modal-searchzip .searchzip-infinite-container {
  border-radius: 7px;
}

.modal-searchzip .searchzip-infinite-container .ant-list-item {
  border: 0;
  margin: 0;
  padding: 0;
}

.modal-searchzip .card-searchzip-item {
  width: 100%;
  border: 0;
  border-top: 1px solid #e3e8ed;
  border-radius: 0;
  cursor: pointer;
}

.modal-searchzip .card-searchzip-item p {
  margin: 0;
}

.modal-searchzip .card-searchzip-item .ant-card-body {
  display: flex;
}

.modal-searchzip .card-searchzip-item .ant-card-body .card-searchzip-item-text {
  margin-left: 10px;
}

.modal-searchzip .card-searchzip-item .keyword-hilight {
  font-weight: bold;
}

.offcanvas-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: #ffffff;
  z-index: 10;
  padding-bottom: 40px !important;
}
@media screen and (min-width: 768px) {
  .offcanvas-wrapper {
    padding-bottom: 60px !important;
  }
}
